import { Box, Container, Paper } from '@mui/material';
import React from 'react';
import { ERROR_MESSAGE } from '../../constants';
import useResizeWindow from '../../hooks/useResizeWindow';
import MainLogo from '../../resources/main-logo.png';

function SyncContent({ contactTotal, hasError }) {
  const { isMobile } = useResizeWindow();

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          margin: 'auto'
        }}
        maxWidth="sm"
      >
        <Paper elevation={6} sx={{ mb: isMobile ? 10 : 1 }}>
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
              </Box>
              {!hasError ? (
                <Box textAlign="center">
                  <p>
                    Nombre total de contacts sur Portail:
                    {contactTotal?.totalSynContacts}
                  </p>
                  <p>
                    Nombre de nouveaux contacts synchronisés:
                    {contactTotal?.totalNewSynContacts}
                  </p>
                </Box>
              ) : (
                <p>{ERROR_MESSAGE}</p>
              )}
            </Container>
          </Container>
        </Paper>
      </Container>
    </>
  );
}

export default SyncContent;
