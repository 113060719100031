import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../session';
import ModalPassword from './EditFormComponents/ModalPassword';
import ModalPause from './EditFormComponents/ModalPause';
import MainLogo from '../resources/main-logo.png';
import { useNavigate } from 'react-router-dom';
import theme from '../styles/theme';
import {
  Box,
  Paper,
  Container,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  Button
} from '@mui/material';
import Loader from './Loader';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { services } from '../services';
import { ERROR_MESSAGE_SAVE } from '../constants';
import useResizeWindow from '../hooks/useResizeWindow';

function Gdpr() {
  const [session] = useContext(SessionContext);
  const [user, setUser] = useState({});

  useEffect(() => {
    services.getUser(session).then(setUser);
  }, [session]);

  return user.id > 0 ? (
    <GdprContent user={user} session={session} />
  ) : (
    <Loader />
  );
}

function GdprContent({ user, session }) {
  const [modalPasswordVisible, setmodalPasswordVisible] = useState(false);
  const [modalPauseVisible, setmodalPauseVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [popup, setPopup] = useState(false);
  const { isMobile } = useResizeWindow();

  const navigate = useNavigate();

  const handleProcessOptout = async () => {
    try {
      await services.postData('/api/optout', user, session);
      setMessage('Votre demande a bien été enregistrée!');
      setShowMessage(true);
      navigate('/logout', { replace: true });
    } catch (error) {
      setMessage(ERROR_MESSAGE_SAVE);
      setShowMessage(true);
    }
  };

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: isMobile ? '80px' : '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          margin: 'auto'
        }}
        maxWidth="sm"
      >
        <Paper elevation={6} sx={{ mb: isMobile ? 10 : 1 }}>
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
                <h2>Droit de retrait</h2>
              </Box>
            </Container>
          </Container>
          <Stack
            component="form"
            noValidate
            spacing={0}
            sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
          >
            <Box sx={{ my: 0 }} className="containerMERForm">
              Vous avez la possibilité de vous retirer de la base Matris. Toutes
              les informations vous concernant seront supprimées, et vous ne
              pourrez plus vous connecter ce portail.
            </Box>
            <Box sx={{ my: 1 }} className="containerMERForm">
              Votre retrait sera diffusé aux communiquants dès demain.
            </Box>
            <Box sx={{ my: 1 }} className="containerMERForm">
              Attention, cette action est irreversible!
            </Box>
            {!showMessage && (
              <>
                <Button
                  sx={{
                    margin: 0,
                    mt: 2,
                    top: 'auto',
                    right: 'auto',
                    bottom: 0,
                    left: 'auto',
                    backgroundColor: theme.palette.primary.main
                  }}
                  variant="contained"
                  size="large"
                  onClick={() => setPopup(true)}
                >
                  Demander mon retrait
                </Button>
                <Dialog
                  open={popup}
                  onClose={() => setPopup(false)}
                  aria-labelledby="responsive-dialog-title"
                  PaperProps={{ style: { backgroundColor: '#FFFFFF' } }}
                >
                  <DialogTitle id="responsive-dialog-title">
                    {'Confirmation'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Est-ce que vous validez votre désinscription? Vous serez
                      supprimé de la base Matris. A la fin de l'opération, vous
                      serez redirigé vers la page d'accueil.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={() => setPopup(false)}>
                      Annuler
                    </Button>
                    <Button onClick={handleProcessOptout} autoFocus>
                      Valider mon retrait
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {showMessage && (
              <Grow
                in={showMessage}
                style={{ transformOrigin: '0 0 0' }}
                {...(showMessage ? { timeout: 1000 } : {})}
              >
                <Container sx={{ mt: 2, ml: 0, pl: 0, textAlign: 'center' }}>
                  {message}
                </Container>
              </Grow>
            )}
          </Stack>
        </Paper>
      </Container>
      <ModalPassword
        modalPasswordVisible={modalPasswordVisible}
        setmodalPasswordVisible={setmodalPasswordVisible}
      />
      <ModalPause
        user={user}
        modalPauseVisible={modalPauseVisible}
        setmodalPauseVisible={setmodalPauseVisible}
      />
    </>
  );
}

export default Gdpr;
