import {
  Box,
  Button,
  Container,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  FormHelperText,
  Tooltip
} from '@mui/material';
import React, { useContext, useState, useEffect, useRef } from 'react';
import UserHeadlines from './UserHeadlines';
import HeadlinesForm from './HeadlinesForm';
import { formDefinition } from './formDefinition';
import { ClassNames } from '@emotion/react';
import { SessionContext } from '../../session';
import theme from '../../styles/theme';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import uuid from 'react-uuid';
import LoginPass from './LoginPass';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import { useNavigate } from 'react-router-dom';
import { services } from '../../services';
import { isIdentifierUnique } from '../../isIdentifierUnique';

import {
  ERROR_MESSAGE_SAVE,
  EMAIL_WRONG_MESSAGE,
  EMAIL_IS_MANDATORY,
  CLASS_NAMES,
  ENDPOINT,
  EMAIL_EXISTS
} from '../../constants';
import { isEmailAddressValid } from '../../emailValidator';
const fieldsEmptyOrIncorrectMsg =
  'Au moins un des champs obligatoires est vide ou mal renseigné, veuillez les vérifier.';
const helpformErrorStyle = { fontSize: '0.9em', mt: 1 };
const noThemeChosenMsg =
  'Vous devez sélectionner au moins une thématique pour valider vos modifications.';

function MainEditForm({ user, thematiques, isMobile, endpoint }) {
  const [session] = useContext(SessionContext);
  const [step, setStep] = useState(1);
  const [values, setValues] = useState(user);
  const [errors, setErrors] = useState({
    identifier_empty: false,
    identifier_wrong: false,
    identifier_not_unique: false,
    password_empty: false,
    password_wrong: false,
    empty_nom: false,
    email_empty: false,
    email_wrong: false,
    empty_thematique: false,
    media_email_wrong: false,
    fetchError: false,
    fetchErrorMsg: ''
  });
  const shouldDisplayErrorMsg =
    step === 1 &&
    (errors.empty_nom ||
      errors.identifier_empty ||
      errors.password_empty ||
      errors.identifier_wrong ||
      errors.password_wrong ||
      errors.identifier_not_unique ||
      errors.empty_email ||
      errors.email_wrong ||
      errors.media_email_wrong);
  const buttonLabel =
    endpoint === ENDPOINT.CREATEACCOUNTREQUEST ? 'Créer mon compte' : 'Valider';

  const handleChange = (fieldName) => async (event) => {
    if (fieldName === 'nom') {
      setErrors((errors) => ({
        ...errors,
        empty_nom: !(event.target.value.length > 0)
      }));
    }
    if (fieldName === 'portail_identifiant') {
      setErrors((errors) => ({
        ...errors,
        identifier_empty: !(event.target.value.length > 0),
        identifier_wrong: !isEmailAddressValid(event.target.value),
        identifier_not_unique: false
      }));
    }
    if (fieldName === 'email') {
      setErrors((errors) => ({
        ...errors,
        email_empty: !(event.target.value.length > 0),
        email_wrong:
          event.target.value !== '' && !isEmailAddressValid(event.target.value),
        identifier_not_unique: false
      }));
    }
    if (fieldName === 'portail_password') {
      setErrors((errors) => ({
        ...errors,
        password_empty: !(event.target.value.length > 0),
        password_wrong: !isOkPassword(event.target.value)
      }));
    }
    setValues({ ...values, [fieldName]: event.target.value });
  };
  const navigate = useNavigate();

  const isOkPassword = (password) => {
    if (
      password.match(/[a-z]+/) &&
      password.match(/[A-Z]+/) &&
      password.match(/[0-9]+/) &&
      password.length >= 8
    ) {
      return true;
    } else {
      return false;
    }
  };

  const submitFormData = async (endpoint) => {
    const thematiqueIds = values['thematiques'];
    values['profil_is_admin'] = JSON.parse(values['profil_is_admin']);

    // Send whole thematiques objects, not only ids
    if (endpoint === 'user') {
      values['thematiques'] = mapThematiqueIdsToThematiques(thematiques, thematiqueIds);
    }
    await services.postData(`/api/${endpoint}`, values, session);

    // Revert back ids only, for backwards compatibility
    values['thematiques'] = thematiqueIds;
  }


  const processThematique = (flattenedThematiques, thematique) => {
    if (!thematique) {
      return;
    }

    flattenedThematiques.push({ id: thematique.value, name: thematique.label });
    thematique.children?.forEach((child) => processThematique(flattenedThematiques, child));
  }

  const mapThematiqueIdsToThematiques = (thematiques, thematiqueIds) => {
    const flattenedThematiques = [];

    thematiques?.forEach(child => processThematique(flattenedThematiques, child));
    return flattenedThematiques
              .filter((thematique) => thematiqueIds.includes(`${thematique.id}`));
  }

  const validateForm = async (e, endpoint) => {
    try {
      await submitFormData(endpoint);
      setStep(currentStep => currentStep + 1);
      return;
    } catch (error) {
      setErrors((errors) => ({
        ...errors,
        fetchError: true,
        fetchErrorMsg: ERROR_MESSAGE_SAVE
      }));
      return error;
    }
  };

  const navHome = () => {
    navigate('/');
  };

  const checkAndSetStep = async (step) => {
    const isNomEmpty = !(values['nom']?.length > 0);
    const isMediaEmailWrong = values.medias?.some(
      (media) => media.email !== '' && !isEmailAddressValid(media.email)
    );
    const isEmailEmpty = !(values['email']?.length > 0);
    const isEmailWrong = !isEmailAddressValid(values['email']);
    let isNotUnique;

    let hasError;
    if (endpoint === ENDPOINT.CREATEUSER) {
      const isIdenEmpty = !(values['portail_identifiant']?.length > 0);
      const isIdenWrong = !isEmailAddressValid(values['portail_identifiant']);
      const isPwdEmpty = !(values['portail_password']?.length > 0);
      const isPwdWrong = !isOkPassword(values['portail_password']);
      if (!(isIdenEmpty || isIdenWrong)) {
        isNotUnique = !(await isIdentifierUnique(
          values['portail_identifiant'],
          session
        ));
      }
      hasError =
        isNomEmpty ||
        isIdenEmpty ||
        isIdenWrong ||
        isPwdEmpty ||
        isPwdWrong ||
        isNotUnique ||
        isMediaEmailWrong;
      setErrors((errors) => ({
        ...errors,
        empty_nom: isNomEmpty,
        identifier_empty: isIdenEmpty,
        identifier_wrong: isIdenWrong,
        identifier_not_unique: isNotUnique,
        password_empty: isPwdEmpty,
        password_wrong: isPwdWrong,
        media_email_wrong: isMediaEmailWrong
      }));
    } else {
      if (
        endpoint === ENDPOINT.CREATEACCOUNTREQUEST &&
        !(isEmailEmpty || isEmailWrong)
      )
        isNotUnique = !(await isIdentifierUnique(values['email']));
      hasError =
        isNomEmpty ||
        isMediaEmailWrong ||
        isEmailEmpty ||
        isEmailWrong ||
        isNotUnique;
      setErrors((errors) => ({
        ...errors,
        empty_nom: isNomEmpty,
        email_empty: isEmailEmpty,
        email_wrong: isEmailWrong,
        media_email_wrong: isMediaEmailWrong,
        identifier_not_unique: isNotUnique
      }));
    }
    if (step === 2 && hasError) {
      return false;
    } else if (step === 3) {
      var isThematiqueEmpty = values['thematiques']?.length < 1;
      setErrors((errors) => ({
        ...errors,
        empty_thematique: isThematiqueEmpty
      }));
      if (isThematiqueEmpty) return false;
    }
    setStep(step);
  };

  useEffect(() => {
    setValues(user);
  }, [user]);

  return (
    <>
      {step === 1 && <HeadlinesForm />}
      <UserHeadlines user={user} />
      {step === 1 && endpoint === ENDPOINT.CREATEUSER && (
        <LoginPass
          values={values}
          errors={errors}
          handleChange={handleChange}
        />
      )}
      {step === 1 && (
        <FormStep1
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {step === 2 && (
        <FormStep2
          values={values}
          thematiques={thematiques}
          setValues={setValues}
          setErrors={setErrors}
        />
      )}
      {step === 3 && (
        <FormStep3
          handleChange={handleChange}
          values={values}
          thematiques={thematiques}
        />
      )}
      {step === 4 && <FormStep4 endpoint={endpoint} errors={errors} />}
      <Container
        className="errorContainer"
        sx={{ mt: 2, mb: 5, textAlign: 'center' }}
      >
        {step > 1 && step < 4 && (
          <Box
            textAlign="center"
            onClick={() => setStep(step - 1)}
            sx={{
              margin: 0,
              top: 'auto',
              right: 'auto',
              bottom: isMobile ? 90 : 30,
              left: 40,
              position: 'fixed',
              backgroundColor: theme.palette.primary.main,
              color: '#FFFFFF'
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => setStep(step + 1)}
            >
              <>Précédent</>
            </Button>
          </Box>
        )}
        {step < 3 && (
          <>
            {shouldDisplayErrorMsg && (
              <FormHelperText error={true} sx={helpformErrorStyle}>
                {fieldsEmptyOrIncorrectMsg}
              </FormHelperText>
            )}
            {errors.empty_thematique && (
              <FormHelperText error={true} sx={helpformErrorStyle}>
                {noThemeChosenMsg}
              </FormHelperText>
            )}

            <Box
              textAlign="center"
              sx={{
                margin: 0,
                top: 'auto',
                right: 40,
                bottom: isMobile ? 90 : 30,
                left: 'auto',
                position: 'fixed',
                backgroundColor: theme.palette.primary.main,
                color: '#FFFFFF'
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => checkAndSetStep(step + 1)}
              >
                {step === 1 && <>Continuer et renseigner mes thématiques</>}
                {step > 1 && <>Valider mes modifications</>}
              </Button>
            </Box>
          </>
        )}
        {step === 3 && (
          <Button
            sx={{
              margin: 0,
              top: 'auto',
              right: 40,
              bottom: isMobile ? 90 : 30,
              left: 'auto',
              position: 'fixed',
              backgroundColor: theme.palette.primary.main
            }}
            variant="contained"
            size="small"
            onClick={(e) => validateForm(e, endpoint)}
          >
            {buttonLabel}
          </Button>
        )}
        {step === 4 && endpoint !== ENDPOINT.CREATEACCOUNTREQUEST && (
          <Button
            sx={{
              margin: '0',
              left: '20%',
              right: '20%',
              top: 'auto',
              bottom: isMobile ? 90 : 20,
              position: 'fixed',
              backgroundColor: theme.palette.primary.main
            }}
            variant="contained"
            onClick={navHome}
          >
            Retour à la page d'accueil
          </Button>
        )}
      </Container>
    </>
  );
}

function FormStep1({ values, handleChange, setValues, errors, setErrors }) {
  const [selectedMedia, setSelectedMedia] = useState(0);
  const [mainMedia, setMainMedia] = useState(0);

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'transparent'
  };

  const addNewMedia = () => {
    const newMedia = {
      key: uuid(),
      codesociete: -1,
      media: '',
      fonction: '',
      fonctiondetail: '',
      email: '',
      telephone: '',
      portable: '',
      adresse1: '',
      adresse2: '',
      cp: '',
      ville: '',
      isMain: false
    };
    setValues({
      ...values,
      medias:
        values.medias?.length > 0 ? [...values.medias, newMedia] : [newMedia]
    });
    setSelectedMedia(values.medias?.length > 0 ? values.medias.length + 1 : 1);
  };

  useEffect(() => {
    values.medias?.length > 0 &&
      values.medias.map((media, i) => {
        if (media.isMain) {
          setSelectedMedia(i);
          setMainMedia(media.codesociete);
          return i;
        } else {
          return 0;
        }
      });
  }, [values.medias]);

  return (
    <>
      {formDefinition
        .filter((control) => control.finalStep !== true)
        .map((control) => {
          return (
            (control.type === 'text' && (
              <FormControl key={control.name}>
                <TextField
                  key={control.name}
                  type="text"
                  label={control.label}
                  value={values[control.name]}
                  onChange={handleChange(control.name)}
                  sx={
                    (((control.name === 'nom' && errors.empty_nom) ||
                      (control.name === 'email' && errors.email_empty)) && {
                      mt: 1,
                      mb: 0
                    }) || { my: 1 }
                  }
                />
                {control.name === 'nom' && errors.empty_nom && (
                  <FormHelperText error={true} sx={{ mt: 0, mb: 1 }}>
                    Le nom est obligatoire
                  </FormHelperText>
                )}
                {control.name === 'email' &&
                  (errors.email_empty ||
                    errors.email_wrong ||
                    errors.identifier_not_unique) && (
                    <FormHelperText error={true} sx={{ mt: 0, mb: 1 }}>
                      {errors.email_empty
                        ? EMAIL_IS_MANDATORY
                        : errors.email_wrong
                        ? EMAIL_WRONG_MESSAGE
                        : EMAIL_EXISTS}
                    </FormHelperText>
                  )}
              </FormControl>
            )) ||
            (control.type === 'list' && (
              <FormControl key={control.name} sx={{ mt: 1 }}>
                <InputLabel id={control.name} sx={{ m: 0 }}>
                  {control.label}
                </InputLabel>
                <Select
                  key={control.name}
                  className="inputMatris"
                  variant="outlined"
                  value={values[control.name] ? values[control.name] : ''}
                  onChange={handleChange(control.name)}
                  label={control.label}
                >
                  {control.content.map((contentItem) => {
                    return (
                      <MenuItem key={contentItem.id} value={contentItem.id}>
                        {contentItem.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )) ||
            (control.type === 'radiobutton' && (
              <FormControl key={control.name} sx={{ mt: 1 }}>
                <Box display="flex">
                  <FormLabel sx={{ my: 'auto' }} id={control.name + '_label'}>
                    {control.label}
                  </FormLabel>
                  <RadioGroup
                    defaultValue={values[control.name]}
                    className="inputMatris"
                    sx={{ pl: 2 }}
                    row
                    label={control.label}
                    name={control.name}
                  >
                    {control.content.map((contentItem) => {
                      return (
                        <FormControlLabel
                          componentsProps={{
                            typography: { fontSize: '0.7rem' }
                          }}
                          sx={{ label: ClassNames.radioLabel }}
                          key={contentItem.id}
                          value={contentItem.id}
                          label={contentItem.label}
                          control={<Radio size="small" />}
                        />
                      );
                    })}
                  </RadioGroup>
                </Box>
              </FormControl>
            )) ||
            (control.type === 'medias' && (
              <FormControl
                key="mediaContainer"
                className="inputMatris"
                sx={{
                  borderRadius: '10px',
                  border: 1,
                  p: 2,
                  my: 2,
                  borderColor: 'rgb(0,0,0,0.3)',
                  '&:hover': { border: 1, borderColor: 'rgb(0,0,0,1)' }
                }}
              >
                <FormLabel
                  style={{
                    marginLeft: '0.5em',
                    marginTop: '-2em',
                    paddingLeft: '0.44em',
                    zIndex: 2,
                    width: '12em',
                    backgroundColor: '#f5f5f6',
                    position: 'absolute',
                    fontSize: '1em'
                  }}
                >
                  Mes fonctions / médias
                </FormLabel>

                <Carousel
                  key="medias"
                  showArrows={true}
                  showThumbs={false}
                  showStatus={false}
                  showIndicators={false}
                  selectedItem={selectedMedia}
                  swipeable={true}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: -7 }}
                      >
                        <ArrowBackIosIcon />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: -7 }}
                      >
                        <ArrowForwardIosIcon />
                      </button>
                    )
                  }
                >
                  {values.medias?.length > 0 &&
                    values.medias.map((item, i) => (
                      <Media
                        key={item.codesociete + '|' + i}
                        _key={item.codesociete + '|' + i}
                        values={values}
                        control={control}
                        data={item}
                        setValues={setValues}
                        i={i}
                        mainMedia={mainMedia}
                        setMainMedia={setMainMedia}
                        setSelectedMedia={setSelectedMedia}
                        setErrors={setErrors}
                      />
                    ))}
                </Carousel>
                <Box textAlign="center">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={addNewMedia}
                  >
                    {values.medias?.length > 0
                      ? 'Je travaille avec un autre média'
                      : 'Je travaille avec un média'}
                  </Button>
                </Box>
              </FormControl>
            ))
          );
        })}
    </>
  );
}

function Media({
  _key,
  values,
  control,
  data,
  setValues,
  i,
  mainMedia,
  setMainMedia,
  setSelectedMedia,
  setErrors
}) {
  const [displayAddressCollab, setdisplayAddressCollab] = useState(false);
  const [media, setMedia] = useState(values.medias[i]);
  const [fonctions, setFonctions] = useState([]);
  const [medias, setMedias] = useState([]);
  const previousController = useRef();
  const [session] = useContext(SessionContext);
  const [isOpenPopup, setIsOpenPopup] = React.useState(false);
  const [mediaEmailErrors, setMediaEmailErrors] = useState([]);

  useEffect(() => {
    if (
      data.adresse1?.length > 0 ||
      data.adresse2?.length > 0 ||
      data.cp?.length > 0 ||
      data.ville?.length > 0
    ) {
      setdisplayAddressCollab(true);
    }
    setErrors((errors) => ({
      ...errors,
      media_email_wrong: mediaEmailErrors.some((item) => item.emailWrong)
    }));
  }, [data.adresse1, data.adresse2, data.cp, data.ville, mediaEmailErrors]);

  useEffect(() => {
    const searchTermFunction = {
      preController: previousController,
      keyword: '',
      session: session,
      className: CLASS_NAMES.FUNCTION,
      callback: setFonctions
    };

    services.getSamples(searchTermFunction);
  }, []);
  const handleMediaChange = (fieldName) => (event, newValue) => {
    var tempMedias = values;
    tempMedias.medias.forEach(function (item) {
      if (
        (item.codesociete === data.codesociete && data.codesociete > 0) ||
        (item.key === data.key && data.codesociete === -1)
      ) {
        if (fieldName === 'media' || fieldName === 'fonction') {
          item[fieldName] = newValue;
        } else if (fieldName === 'isMain') {
          item['isMain'] = true;
        } else {
          item[fieldName] = event.target.value;
        }
      } else {
        if (fieldName === 'isMain') {
          item['isMain'] = false;
        }
      }
      if (fieldName === 'email') {
        setMediaEmailErrors([
          {
            id: data.codesociete,
            emailWrong:
              event.target.value !== '' &&
              !isEmailAddressValid(event.target.value)
          }
        ]);
      }
    });
    setValues(tempMedias);

    if (fieldName === 'isMain') {
      setMedia({ ...media, [fieldName]: newValue || newValue });
    } else {
      setMedia({ ...media, [fieldName]: newValue || event.target.value });
    }
  };

  const onMediaChange = (event, value) => {
    handleMediaChange('media')(event, value);
    const searchTermMedia = {
      preController: previousController,
      keyword: value,
      session: session,
      className: CLASS_NAMES.MEDIA,
      callback: setMedias
    };
    if (value?.length > 1) {
      services.getSamples(searchTermMedia);
    } else {
      setMedias([]);
    }
  };

  const handleChangeMainMedia = (event, newValue) => {
    handleMediaChange('isMain')(event, newValue);
    if (newValue) {
      if (data.codesociete > 0) {
        setMainMedia(data.codesociete);
      } else {
        setMainMedia(_key);
      }
    }
  };

  const handleRemoveMedia = (event, newValue) => {
    var tempMedias = values;
    tempMedias.medias = tempMedias.medias.filter(
      (item) =>
        (item.codesociete !== data.codesociete && data.codesociete > 0) ||
        (item.key !== data.key && data.codesociete === -1)
    );

    setValues({ ...values, medias: tempMedias.medias });
    setSelectedMedia(1);
    setIsOpenPopup(false);
  };

  const handlePopupOpen = () => {
    setIsOpenPopup(true);
  };

  const handlePopupClose = () => {
    setIsOpenPopup(false);
  };

  return (
    <>
      <Container sx={{ mb: 2 }}>
        <Autocomplete
          key={control.name + '.' + data.codesociete + '.media'}
          freeSolo
          options={medias.map((option) => option.title)}
          onInputChange={onMediaChange}
          value={media.media}
          readOnly={media.codesociete > 0 ? true : false}
          onChange={handleMediaChange('media')}
          className="inputMatris"
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                control.content.filter((element) => element.name === 'media')[0]
                  .label
              }
              variant="outlined"
              sx={{ width: '100%', my: 1 }}
            />
          )}
        />
        <Autocomplete
          key={control.name + '.' + data.codesociete + '.fonction'}
          className="inputMatris"
          options={fonctions.map((option) => option.title)}
          isOptionEqualToValue={(option, value) =>
            option?.name === value?.name || value === ''
          }
          value={media.fonction || null}
          onChange={handleMediaChange('fonction')}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                control.content.filter(
                  (element) => element.name === 'fonction'
                )[0].label
              }
              variant="outlined"
              sx={{ width: '100%', my: 1 }}
            />
          )}
        />
        <TextField
          key={control.name + '.' + data.codesociete + '.fonctiondetail'}
          className="inputMatris"
          variant="outlined"
          type="text"
          label={
            control.content.filter(
              (element) => element.name === 'fonctiondetail'
            )[0].label
          }
          value={media.fonctiondetail}
          onChange={handleMediaChange('fonctiondetail')}
          sx={{ width: '100%', my: 1 }}
        />
        <TextField
          key={control.name + '.' + data.codesociete + '.email'}
          className="inputMatris"
          variant="outlined"
          type="text"
          label={
            control.content.filter((element) => element.name === 'email')[0]
              ?.label
          }
          value={media.email}
          onChange={handleMediaChange('email')}
          sx={{ width: '100%', my: 1 }}
          error={
            mediaEmailErrors.find(
              (item) => (item.id = data.codesociete && item.emailWrong)
            ) !== undefined
          }
          helperText={
            mediaEmailErrors.find(
              (item) => (item.id = data.codesociete && item.emailWrong)
            ) !== undefined && EMAIL_WRONG_MESSAGE
          }
        />
        <TextField
          key={control.name + '.' + data.codesociete + '.telephone'}
          className="inputMatris"
          variant="outlined"
          type="text"
          label={
            control.content.filter((element) => element.name === 'telephone')[0]
              ?.label
          }
          value={media.telephone}
          onChange={handleMediaChange('telephone')}
          sx={{ width: '100%', my: 1 }}
        />

        <TextField
          key={control.name + '.' + data.codesociete + '.portable'}
          className="inputMatris"
          variant="outlined"
          type="text"
          label={
            control.content.filter((element) => element.name === 'portable')[0]
              ?.label
          }
          value={media.portable}
          onChange={handleMediaChange('portable')}
          sx={{ width: '100%', my: 1 }}
        />

        {values.medias.length > 0 && (
          <Tooltip
            title={<span style={{ fontSize: 14 }}>Média principal</span>}
            placement="right"
          >
            <Checkbox
              icon={<StarBorderOutlinedIcon />}
              checkedIcon={<StarIcon />}
              checked={
                (data.codesociete === mainMedia && data.codesociete > 0) ||
                mainMedia === _key
              }
              onChange={handleChangeMainMedia}
              sx={{ position: 'absolute', right: 20, top: 5 }}
            />
          </Tooltip>
        )}
        <Tooltip
          title={<span style={{ fontSize: 14 }}>Supprimer cette fonction</span>}
          placement="right"
        >
          <Checkbox
            icon={<DeleteIcon />}
            checkedIcon={<DeleteIcon />}
            checked={true}
            onChange={handlePopupOpen}
            sx={{ position: 'absolute', right: 50, top: 5 }}
          />
        </Tooltip>
        <Dialog
          open={isOpenPopup}
          onClose={handlePopupClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirmation de la suppression
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vous supprimerez la fonction pour le média affiché. Les autres
              fonctions ne seront pas supprimées.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupClose}>Annuler</Button>
            <Button onClick={handleRemoveMedia} autoFocus>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
        {displayAddressCollab && (
          <>
            <TextField
              key={control.name + '.' + data.codesociete + '.adresse1'}
              className="inputMatris"
              variant="standard"
              type="text"
              label={
                control.content.filter(
                  (element) => element.name === 'adresse1'
                )[0].label
              }
              value={media.adresse1}
              onChange={handleMediaChange('adresse1')}
              sx={{ width: '100%' }}
            />
            <TextField
              key={control.name + '.' + data.codesociete + '.adresse2'}
              className="inputMatris"
              variant="standard"
              type="text"
              label={
                control.content.filter(
                  (element) => element.name === 'adresse2'
                )[0].label
              }
              value={media.adresse2}
              onChange={handleMediaChange('adresse2')}
              sx={{ width: '100%' }}
            />
            <TextField
              key={control.name + '.' + data.codesociete + '.cp'}
              className="inputMatris"
              variant="standard"
              type="text"
              label={
                control.content.filter((element) => element.name === 'cp')[0]
                  .label
              }
              value={media.cp}
              onChange={handleMediaChange('cp')}
              sx={{ width: '100%' }}
            />
            <TextField
              key={control.name + '.' + data.codesociete + '.ville'}
              className="inputMatris"
              variant="standard"
              type="text"
              label={
                control.content.filter((element) => element.name === 'ville')[0]
                  .label
              }
              value={media.ville}
              onChange={handleMediaChange('ville')}
              sx={{ width: '100%' }}
            />
          </>
        )}
        <FormControlLabel
          componentsProps={{
            typography: { fontSize: '0.7rem', textAlign: 'justify' }
          }}
          sx={{ mt: 2 }}
          control={
            <Checkbox
              checked={displayAddressCollab}
              onChange={() => setdisplayAddressCollab(!displayAddressCollab)}
              name="setdisplayAddressCollab"
              size="small"
            />
          }
          label="Cliquez ici si vous souhaitez recevoir des informations à une adresse postale différente du média"
        />
      </Container>
    </>
  );
}

function FormStep2({ values, thematiques, setValues, setErrors }) {
  const defaultThematiques = thematiques
    .filter((thematique) => thematique.defaultOpened === true)
    .map((them) => {
      return them.value;
    });

  const [state, setState] = useState({
    checked: values.thematiques,
    expanded: defaultThematiques
  });

  useEffect(() => {
    const tempValues = values;
    tempValues.thematiques = state.checked;
    setValues(tempValues);
    setErrors((errors) => ({
      ...errors,
      empty_thematique: tempValues?.thematiques?.length < 1
    }));
  }, [state, setValues, values, setErrors]);
  return (
    <Box>
      {thematiques.length ? (
        <CheckboxTree
          sx={{ display: 'flex', flexDirection: 'column' }}
          height="50%"
          showNodeIcon={false}
          nodes={thematiques}
          checked={state.checked}
          expanded={state.expanded}
          onCheck={(checked) => setState((prev) => ({ ...prev, checked }))}
          onExpand={(expanded) => setState((prev) => ({ ...prev, expanded }))}
        />
      ) : (
        <Box sx={{ py: '10px', textAlign: 'center' }}>
          Themes are not defined
        </Box>
      )}
    </Box>
  );
}

function MediaDetail({ label, value }) {
  return (
    <>
      <b>{label}</b> :{' '}
      {value ? value : <span className="nonprecise">Aucun/Non précisé</span>}
      <br />
    </>
  );
}

function FormStep3({ handleChange, values, thematiques }) {
  const eltsToBeExcluded = ['media', 'adresse1', 'adresse2', 'cp', 'ville'];
  return (
    <>
      <section className="recapSection">
        {formDefinition.map((control) => {
          return (
            (control.type === 'text' && (
              <React.Fragment key={control.id}>
                <article>
                  <b>{control.label}</b> :{' '}
                  {values[control.name] ? (
                    values[control.name]
                  ) : (
                    <span className="nonprecise">Aucun/Non précisé</span>
                  )}
                </article>
              </React.Fragment>
            )) ||
            (control.type === 'comments' && control.finalStep === true && (
              <FormControl fullWidth key={control.name}>
                <TextField
                  key={control.name}
                  multiline
                  rows={4}
                  className="inputMatris"
                  variant="standard"
                  type="text"
                  label={control.label}
                  defaultValue={values[control.name]}
                  onChange={handleChange(control.name)}
                />
              </FormControl>
            )) ||
            ((control.type === 'list' || control.type === 'radiobutton') && (
              <React.Fragment key={control.id}>
                <article>
                  <b>{control.label}</b> :{' '}
                  {values[control.name] ? (
                    control.content.filter(
                      (element) => element.id === values[control.name]
                    )[0].label
                  ) : (
                    <span className="nonprecise">Aucun/Non précisé</span>
                  )}
                </article>
              </React.Fragment>
            )) ||
            (control.type === 'medias' && (
              <React.Fragment key={control.id}>
                {values.medias?.length > 0 &&
                  values.medias
                    .filter((mediaItem) => mediaItem.media.length > 0)
                    .map((item, i) => (
                      <article
                        className="medias"
                        key={item.codesociete + '|' + i}
                      >
                        <MediaDetail
                          label={
                            control?.content?.filter(
                              (element) => element.name === 'media'
                            )[0].label
                          }
                          value={item['media']}
                        />
                        <article className="subMedia">
                          {control?.content?.length > 0 &&
                            control.content
                              .filter(
                                (elem) => !eltsToBeExcluded.includes(elem.name)
                              )
                              .map((e) => (
                                <MediaDetail
                                  key={item.codesociete + '|' + e.id}
                                  label={e.label}
                                  value={item[e.name]}
                                />
                              ))}
                          {item.adresse1 ||
                          item.adresse2 ||
                          item.cp ||
                          item.ville ? (
                            <>
                              <span className="fieldTitle">Adresse : </span>
                              {item.adresse1 ? item.adresse1 + ' ' : ''}
                              {item.adresse2 ? item.adresse2 + ' ' : ''}
                              {item.cp ? item.cp + ' ' : ''}
                              {item.ville ? item.ville + ' ' : ''}
                            </>
                          ) : (
                            ''
                          )}
                        </article>
                      </article>
                    ))}
              </React.Fragment>
            )) ||
            (control.type === 'thematiques' &&
              values.thematiques?.length > 0 && (
                <section key={control.id} className="thematics">
                  <b>{control.label}</b> :
                  {thematiques
                    .filter(
                      (element) =>
                        element.children?.length >= 0 &&
                        element.children?.filter((elt) =>
                          values.thematiques?.includes(elt.value.toString())
                        ).length > 0
                    )
                    .map((element) => {
                      return (
                        <article
                          className="selectedThematics"
                          key={element.value}
                        >
                          <b>{element.label}</b> (
                          {thematiques
                            .filter(
                              (subelement) => subelement.value === element.value
                            )[0]
                            .children.filter((subfiltered) =>
                              values.thematiques?.includes(
                                subfiltered.value.toString()
                              )
                            )
                            .map((subfiltered, i) => {
                              return (
                                <React.Fragment key={subfiltered.value}>
                                  {i > 0
                                    ? ', ' + subfiltered.label
                                    : subfiltered.label}
                                </React.Fragment>
                              );
                            })}
                          )
                        </article>
                      );
                    })}
                </section>
              ))
          );
        })}
      </section>
    </>
  );
}

function FormStep4({ endpoint, errors }) {
  return (
    <>
      <Box sx={{ pt: 3 }}>
        {errors.fetchError && (
          <FormHelperText error={true} sx={helpformErrorStyle}>
            {errors.fetchErrorMsg}
          </FormHelperText>
        )}
        {!errors.fetchError &&
          (endpoint === ENDPOINT.CREATEUSER ? (
            <span>
              Le profil du journaliste a été créé. Il peut dès à présent se
              connecter avec l'identifiant et le mot de passe saisis.
            </span>
          ) : endpoint === ENDPOINT.CREATEACCOUNTREQUEST ? (
            (!errors.fetchError && (
              <span>
                Un mail de vérification sera envoyé pour confirmer votre adresse
                e-mail. Le lien restera valide pendant 24 heures. Merci !
              </span>
            )) ||
            (errors.fetchError && (
              <FormHelperText error={true} sx={helpformErrorStyle}>
                {errors.fetchErrorMsg}
              </FormHelperText>
            ))
          ) : (
            <span>
              Les modifications ont été intégrées avec succès. Vous pouvez
              accéder de nouveau au formulaire en cliquant sur le bouton
              ci-dessous.
            </span>
          ))}
      </Box>
    </>
  );
}

export default MainEditForm;
