import {
  InputAdornment,
  Button,
  Box,
  Stack,
  InputLabel,
  FormControl,
  FormHelperText,
  FilledInput,
  IconButton
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import React, { useState, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { SessionContext } from '../../session';
import theme from '../../styles/theme';
import { services } from '../../services';
import { ERROR_MESSAGE } from '../../constants';

const ModalPassword = ({ modalPasswordVisible, setmodalPasswordVisible }) => {
  const [session] = useContext(SessionContext);
  const [valuesPassword, setvaluesPassword] = useState({
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
    showRepeatNewPassword: false
  });
  const [errors, setErrors] = useState({
    currentPassword: false,
    newPassword: false,
    repeatNewPassword: false,
    fetchError: false,
    fetchErrorMsg: '',
    emptyCurrentPassword: false,
    emptyNewPassword: false
  });

  const handleChangePassword = (fieldName) => (event) => {
    var currValue = event.target.value;
    if (fieldName === 'repeatNewPassword') {
      currValue === valuesPassword.newPassword
        ? setErrors({ ...errors, repeatPassword: false })
        : setErrors({ ...errors, repeatPassword: true });
    }
    if (fieldName === 'currentPassword') {
      currValue.length > 0
        ? setErrors({ ...errors, emptyCurrentPassword: false })
        : setErrors({ ...errors, emptyCurrentPassword: true });
    }
    if (fieldName === 'newPassword') {
      currValue.length > 0
        ? setErrors({ ...errors, emptyNewPassword: false })
        : setErrors({ ...errors, emptyNewPassword: true });
    }

    setvaluesPassword({ ...valuesPassword, [fieldName]: event.target.value });
  };

  const handleShowPassword = (showPasswordField) => {
    setvaluesPassword({
      ...valuesPassword,
      [showPasswordField]: !valuesPassword.showPasswordField
    });
  };

  const validateChangePassword = async (event) => {
    if (!valuesPassword.currentPassword.length > 0) {
      setErrors({ ...errors, emptyCurrentPassword: true });
    }
    if (!valuesPassword.newPassword.length > 0) {
      setErrors({ ...errors, emptyNewPassword: true });
    }
    if (!(valuesPassword.newPassword === valuesPassword.repeatNewPassword)) {
      setErrors({ ...errors, repeatPassword: true });
    }
    if (
      !(valuesPassword.newPassword === valuesPassword.repeatNewPassword) ||
      !valuesPassword.newPassword.length > 0 ||
      !valuesPassword.currentPassword.length > 0
    ) {
      return;
    }
    try {
      const body = {
        currentPassword: valuesPassword.currentPassword,
        newPassword: valuesPassword.newPassword
      };
      await services.postData('/api/changepassword', body, session);
      setmodalPasswordVisible(false);
      setErrors({
        currentPassword: false,
        newPassword: false,
        repeatNewPassword: false,
        fetchError: false,
        fetchErrorMsg: '',
        emptyCurrentPassword: false,
        emptyNewPassword: false
      });
      setvaluesPassword({
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: '',
        showCurrentPassword: false,
        showNewPassword: false,
        showRepeatNewPassword: false
      });
      return;
    } catch (error) {
      setErrors({
        ...errors,
        fetchError: true,
        fetchErrorMsg: error.msg ?? ERROR_MESSAGE
      });
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#f5f5f6',
    border: '1px solid #424242',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalPasswordVisible}
      onClose={() => {
        setmodalPasswordVisible(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={modalPasswordVisible}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Changement de mot de passe
          </Typography>
          <Stack
            component="form"
            noValidate
            spacing={0}
            sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
          >
            <FormControl variant="filled" sx={{ mb: 3 }}>
              <InputLabel htmlFor="password-field">
                Mot de passe actuel
              </InputLabel>
              <FilledInput
                id="currentPassword"
                type={valuesPassword.showCurrentPassword ? 'text' : 'password'}
                value={valuesPassword.currentPassword}
                onChange={handleChangePassword('currentPassword')}
                error={errors.currentPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleShowPassword('showCurrentPassword')}
                      edge="end"
                    >
                      {valuesPassword.showCurrentPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.emptyCurrentPassword && (
                <FormHelperText error={errors.emptyCurrentPassword}>
                  Le mot de passe est obligatoire
                </FormHelperText>
              )}
            </FormControl>

            <FormControl variant="filled" sx={{ mb: 3 }}>
              <InputLabel htmlFor="password-field">
                Nouveau mot de passe
              </InputLabel>
              <FilledInput
                id="newPassword"
                type={valuesPassword.showNewPassword ? 'text' : 'password'}
                value={valuesPassword.newPassword}
                onChange={handleChangePassword('newPassword')}
                error={errors.newPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleShowPassword('showNewPassword')}
                      edge="end"
                    >
                      {valuesPassword.showNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.emptyNewPassword && (
                <FormHelperText error={errors.emptyNewPassword}>
                  Le mot de passe est obligatoire
                </FormHelperText>
              )}
            </FormControl>

            <FormControl variant="filled">
              <InputLabel htmlFor="password-field">
                Confirmation du mot de passe
              </InputLabel>
              <FilledInput
                id="repeatNewPassword"
                type={
                  valuesPassword.showRepeatNewPassword ? 'text' : 'password'
                }
                value={valuesPassword.repeatNewPassword}
                onChange={handleChangePassword('repeatNewPassword')}
                error={errors.repeatNewPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleShowPassword('showRepeatNewPassword')
                      }
                      edge="end"
                    >
                      {valuesPassword.showRepeatNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              {errors.repeatPassword && (
                <FormHelperText error={errors.repeatPassword}>
                  Le mot de passe doit être similaire au précédent
                </FormHelperText>
              )}
            </FormControl>
            <FormHelperText sx={{ my: 2 }} error={errors.newPassword}>
              Les mots de passe doivent contenir 8 caractères, au moins une
              lettre en majuscule, une lettre en minuscule et un chiffre
            </FormHelperText>
            {errors.fetchError && (
              <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
            )}
          </Stack>
          <Button
            sx={{
              margin: 0,
              top: 'auto',
              right: 'auto',
              bottom: 20,
              left: 60,
              position: 'fixed'
            }}
            variant="outlined"
            size="medium"
            onClick={() => {
              setmodalPasswordVisible(false);
            }}
          >
            Annuler
          </Button>
          <Button
            sx={{
              margin: 0,
              top: 'auto',
              right: 60,
              bottom: 20,
              left: 'auto',
              position: 'fixed',
              backgroundColor: theme.palette.primary.main
            }}
            variant="contained"
            size="medium"
            onClick={validateChangePassword}
          >
            Valider
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalPassword;
