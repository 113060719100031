import React, { useContext, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormControlLabel } from '@mui/material';
import { Box, Stack, RadioGroup, Radio, FormHelperText } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import * as dateFnsLocales from 'date-fns/locale';
import { SessionContext } from '../../session';
import theme from '../../styles/theme';
import { ERROR_MESSAGE_SAVE } from '../../constants';
import { services } from '../../services';

const ModalPause = ({ user, modalPauseVisible, setmodalPauseVisible }) => {
  const [session] = useContext(SessionContext);
  const [visibleDates, setVisibleDates] = useState(user.debutpause ? 'B' : 'A');
  const [state, setState] = useState([
    {
      startDate: user.debutpause
        ? new Date(Date.parse(user.debutpause))
        : new Date(),
      endDate: user.finpause ? new Date(Date.parse(user.finpause)) : new Date(),
      key: 'selection'
    }
  ]);
  const [errors, setErrors] = useState({
    fetchError: false,
    fetchErrorMsg: ''
  });
  const updataOrRemovePase = async (isUpdate) => {
    try {
      if (isUpdate) {
        await services.postData('/api/updatepause', state[0], session);
      } else {
        await services.postData('/api/removepause', { body: null }, session);
      }
      setmodalPauseVisible(false);
      setErrors({
        fetchError: false,
        fetchErrorMsg: ''
      });
      setmodalPauseVisible(false);
    } catch (error) {
      setErrors({
        ...errors,
        fetchError: true,
        fetchErrorMsg: error.msg ?? ERROR_MESSAGE_SAVE
      });
    }
  };

  const setPause = () => {
    updataOrRemovePase(visibleDates === 'B');
  };

  const toggleDates = () => {
    if (visibleDates === 'B') {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);
    }
    setVisibleDates(visibleDates === 'B' ? 'A' : 'B');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#f5f5f6',
    border: '1px solid #424242',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    textAlign: 'justify'
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalPauseVisible}
      onClose={() => {
        setmodalPauseVisible(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={modalPauseVisible}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Mise en pause de mon compte
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            Vous avez la possibilité de mettre en pause votre compte. Les
            utilisateurs de la base Matris seront notifiés afin de ne plus vous
            envoyer d'informations durant cette période.
          </Typography>
          <Stack
            component="form"
            noValidate
            spacing={0}
            sx={{ bgcolor: '#f5f5f6', pt: 2, mb: 5 }}
          >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={visibleDates}
              onChange={toggleDates}
            >
              <FormControlLabel
                value="A"
                control={<Radio />}
                label="Compte actif"
              />
              <FormControlLabel
                value="B"
                control={<Radio />}
                label="Compte en pause"
              />
            </RadioGroup>
            {visibleDates === 'B' && (
              <DateRangePicker
                locale={dateFnsLocales['fr']}
                ranges={state}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                dateDisplayFormat="dd MMM yyyy"
              />
            )}
            {errors.fetchError && (
              <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
            )}
          </Stack>
          <Button
            sx={{
              margin: 0,
              top: 'auto',
              right: 'auto',
              bottom: 20,
              left: 60,
              position: 'fixed'
            }}
            variant="outlined"
            size="medium"
            onClick={() => {
              setmodalPauseVisible(false);
            }}
          >
            Annuler
          </Button>
          <Button
            sx={{
              margin: 0,
              top: 'auto',
              right: 60,
              bottom: 20,
              left: 'auto',
              position: 'fixed',
              backgroundColor: theme.palette.primary.main
            }}
            variant="contained"
            size="medium"
            onClick={setPause}
          >
            Valider
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalPause;
