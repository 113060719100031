import React, { useState, useEffect, useContext } from 'react';
import { SessionContext } from '../session';
import Drawer from '@mui/material/Drawer';
import ModalPassword from './EditFormComponents/ModalPassword';
import ModalPause from './EditFormComponents/ModalPause';
import {
  Divider,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  navBarDivider,
  navBarLabel,
  navBarListItem,
  navBarListItemActive,
  svgIcon
} from '../styles/globalstyles';
import {
  Business,
  InsertDriveFile,
  Lock,
  Logout,
  Person,
  Security,
  Notifications
} from '@mui/icons-material';
import MainLogo from '../resources/main-logo.png';
import theme from '../styles/theme';
import { services } from '../services';

export const SideBar = (props) => {
  const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [modalPasswordVisible, setmodalPasswordVisible] = useState(false);
  const [modalPauseVisible, setmodalPauseVisible] = useState(false);
  const [session] = useContext(SessionContext);
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const handleNavigate = (navigation) => {
    props.onClose();
    navigate(navigation);
  };

  useEffect(() => {
    Promise.all([services.getUser(session), services.isAdmin(session)])
      .then(([user, resp]) => {
        setUser(user);
        setIsAdmin(resp.isAdmin);
      })
      .catch((error) => {
        console.log('Error : ', error);
      });
  }, [session]);

  return (
    <>
      <Drawer
        variant={props.isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={props.isNavOpen}
        onClose={props.onClose}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: '#e1e2e1',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
            borderRight: 'none',
            display: 'flex',
            alignItems: 'center',
            paddingTop: '20px'
          }
        }}
      >
        <List>
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '30px 0 50px 40px'
            }}
          >
            {!props.isMobile && (
              <Box
                onClick={() => handleNavigate('/')}
                component="img"
                src={MainLogo}
                sx={{
                  width: '150px',
                  cursor: 'pointer',
                  backgroundColor: '#e1e2e1'
                }}
              />
            )}
          </ListItem>
          <ListItem key={'networkLabel'} sx={navBarLabel}>
            <InputLabel>Mon réseau</InputLabel>
          </ListItem>
          <ListItem
            key={'relation'}
            sx={[
              navBarListItem,
              location.pathname === '/relation' && navBarListItemActive
            ]}
          >
            <ListItemButton
              sx={{
                borderRadius: '10px'
              }}
              onClick={() => handleNavigate('/relation')}
              selected={location.pathname === '/relation'}
            >
              <ListItemIcon sx={svgIcon}>
                <InsertDriveFile />
              </ListItemIcon>
              <ListItemText primary={'Mise en relation'} />
            </ListItemButton>
          </ListItem>
          {isAdmin && (
            <>
              <ListItem
                key={'entities'}
                sx={[
                  navBarListItem,
                  location.pathname === '/entities' && navBarListItemActive
                ]}
              >
                <ListItemButton
                  sx={{ borderRadius: '10px' }}
                  onClick={() => handleNavigate('/entities')}
                  selected={location.pathname === '/entities'}
                >
                  <ListItemIcon>
                    <Business sx={svgIcon} />
                  </ListItemIcon>
                  <ListItemText primary={'Sociétés / Agences'} />
                </ListItemButton>
              </ListItem>
              <Divider variant={'middle'} sx={navBarDivider} />
              <ListItem key={'profileLabel'} sx={navBarLabel}>
                <InputLabel>Mon profil</InputLabel>
              </ListItem>
            </>
          )}
          <ListItem
            key={'user'}
            sx={[
              navBarListItem,
              location.pathname === '/user' && navBarListItemActive
            ]}
          >
            <ListItemButton
              sx={{ borderRadius: '10px' }}
              onClick={() => handleNavigate('/user')}
              selected={location.pathname === '/user'}
            >
              <ListItemIcon sx={{ alignSelf: 'center', display: 'flex' }}>
                <Person sx={svgIcon} />
              </ListItemIcon>
              <ListItemText primary={'Mon compte'} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={'password'}
            sx={[navBarListItem, modalPasswordVisible && navBarListItemActive]}
          >
            <ListItemButton
              sx={{ borderRadius: '10px' }}
              onClick={() => setmodalPasswordVisible(true)}
              selected={location.pathname === '/password'}
            >
              <ListItemIcon>
                <Lock sx={svgIcon} />
              </ListItemIcon>
              <ListItemText primary={'Mot de passe'} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={'gdpr'}
            sx={[
              navBarListItem,
              location.pathname === '/gdpr' && navBarListItemActive
            ]}
          >
            <ListItemButton
              sx={{ borderRadius: '10px' }}
              onClick={() => handleNavigate('/gdpr')}
              selected={location.pathname === '/gdpr'}
            >
              <ListItemIcon>
                <Security sx={svgIcon} />
              </ListItemIcon>
              <ListItemText primary={'Droit de retrait'} />
            </ListItemButton>
          </ListItem>
          <Divider
            variant={'middle'}
            sx={{
              width: '80%',
              margin: '25px 16px 25px 16px'
            }}
          />
          <ListItem
            key={'pausing'}
            sx={[navBarListItem, modalPauseVisible && navBarListItemActive]}
          >
            <ListItemButton
              sx={{ borderRadius: '10px' }}
              onClick={() => setmodalPauseVisible(true)}
              selected={location.pathname === '/pausing'}
            >
              <ListItemIcon>
                <Notifications sx={svgIcon} />
              </ListItemIcon>
              <ListItemText primary={'Mise en pause'} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={'logout'}
            sx={[
              navBarListItem,
              location.pathname === '/logout' && navBarListItemActive
            ]}
          >
            <ListItemButton
              sx={{ borderRadius: '10px' }}
              // onClick={() => handleNavigate("/logout")}
              onClick={() => {
                setLogoutDialogVisible(true);
              }}
              selected={location.pathname === '/logout'}
            >
              <ListItemIcon>
                <Logout sx={svgIcon} />
              </ListItemIcon>
              <ListItemText primary={'Déconnexion'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Dialog
        open={logoutDialogVisible}
        onClose={() => setLogoutDialogVisible(false)}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            width: '500px',
            height: '200px',
            borderRadius: '20px'
          }
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmez-vous la déconnexion?
        </DialogTitle>

        <DialogActions>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '20px'
            }}
          >
            <Button
              sx={{
                borderColor: theme.palette.primary.main,
                border: 1,
                width: '140px',
                borderRadius: '20px',
                height: '46px'
              }}
              autoFocus
              onClick={() => setLogoutDialogVisible(false)}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={() => handleNavigate('/logout')}
              sx={{
                backgroundColor: theme.palette.primary.main,
                height: '46px',
                borderRadius: '20px',
                width: '140px'
              }}
            >
              Déconnexion
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ModalPassword
        modalPasswordVisible={modalPasswordVisible}
        setmodalPasswordVisible={setmodalPasswordVisible}
      />
      <ModalPause
        user={user}
        modalPauseVisible={modalPauseVisible}
        setmodalPauseVisible={setmodalPauseVisible}
      />
    </>
  );
};
