import React from "react";
import { Box } from "@mui/material";

function HeadlinesForm() {
  return (
    <Box sx={{ my: 0 }} className='containerHeadlinesForm'>
      Matris est une base de données médias co-construite entre les journalistes et les syndicats professionnels des RP
      français.
      <ul className='headerListing'>
        <li>
          Une base 100% RGPD, vous permettant d'accéder directement à vos informations afin d'exercer vos droits de
          modification et de retrait de manière précise et transparente.
        </li>
        <li>
          Une base anti-spam rendant le ciblage très performant et en vous donnant la possibilité de vous désinscrire de
          certaines thématiques, de certains émetteurs.
        </li>
        <li>Une base vous permettant de déposer des requêtes quand vous cherchez une information.</li>
      </ul>
    </Box>
  );
}

export default HeadlinesForm;
