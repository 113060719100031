import React, { useState } from 'react';
import MainLogo from '../resources/main-logo.png';
import {
  Box,
  Paper,
  Container,
  Stack,
  TextField,
  Button,
  Grow
} from '@mui/material';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import theme from '../styles/theme';
import { services } from '../services';

function ForgottenPassword() {
  return <ForgottenPasswordContent />;
}

function ForgottenPasswordContent() {
  const [email, setEmail] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const resetPassword = async () => {
    setShowMessage(true);
    try {
      await services.postData('/api/resetpassword', { email: email });
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          margin: 'auto'
        }}
        maxWidth="sm"
      >
        <Paper elevation={6} sx={{ mb: 1 }}>
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
                <h2>Mot de passe oublié</h2>
              </Box>
            </Container>
          </Container>
          <Stack
            component="form"
            noValidate
            spacing={0}
            sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
          >
            <Box sx={{ my: 0 }} className="containerMERForm">
              Si vous avez déja un compte dans la base Matris mais que vous ne
              pouvez pas vous connecter, vous pouvez réinitialiser votre mot de
              passe en saisissant votre email ci-dessous.
            </Box>
            <TextField
              key="email"
              variant="standard"
              type="text"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ px: 2, '& label': { px: 2 } }}
            />
            {showMessage && (
              <Grow
                in={showMessage}
                style={{ transformOrigin: '0 0 0' }}
                {...(showMessage ? { timeout: 1000 } : {})}
              >
                <Container sx={{ mt: 2, textAlign: 'justify' }}>
                  Merci! Si l'email correspond à un compte existant, vous
                  recevrez rapidement un email vous permettant de réinitialiser
                  votre mot de passe!
                  <br />
                  <Button
                    sx={{
                      mt: 2,
                      top: 'auto',
                      right: 'auto',
                      bottom: 0,
                      left: 'auto',
                      width: '100%',
                      backgroundColor: theme.palette.primary.main
                    }}
                    href="/"
                    variant="contained"
                    size="large"
                  >
                    Page de connexion
                  </Button>
                </Container>
              </Grow>
            )}
            {!showMessage && (
              <Button
                sx={{
                  margin: 0,
                  mt: 2,
                  top: 'auto',
                  right: 'auto',
                  bottom: 0,
                  left: 'auto',
                  backgroundColor: theme.palette.primary.main
                }}
                type="submit"
                variant="contained"
                size="large"
                onClick={resetPassword}
              >
                Valider
              </Button>
            )}
          </Stack>
        </Paper>
      </Container>
    </>
  );
}

export default ForgottenPassword;
