import React, { useState, useEffect } from 'react';
import FooterForm from './EditFormComponents/FooterForm';
import MainEditForm from './EditFormComponents/MainEditForm';
import Loader from './Loader';
import MainLogo from '../resources/main-logo.png';
import { Box, Paper, Container, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { services } from '../services';
import { ERROR_MESSAGE } from '../constants';
import useResizeWindow from '../hooks/useResizeWindow';

function CreateAccountRequest() {
  const [user, setUser] = useState({});
  const [thematiques, setThematiques] = useState([]);
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(false);
  const { isMobile } = useResizeWindow;

  useEffect(() => {
    services
      .getDefaultUser()
      .then((user) => {
        setUser(user);
        services.getThemes().then((themes) => {
          setThematiques(themes);
        });
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setDataLoaded(true);
      });
  }, [navigate]);

  if (dataLoaded) {
    return (
      <>
        <Container
          sx={{
            position: 'absolute',
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 'auto'
          }}
          maxWidth="sm"
        >
          <Paper elevation={6} sx={{ mb: isMobile ? 10 : 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '20px'
              }}
            >
              <Container className="LogoForm">
                <Box textAlign="center">
                  <img src={MainLogo} alt="Main logo" />
                  <h2>Création d'un nouveau profil</h2>
                </Box>
              </Container>
            </Container>
            <Stack
              component="form"
              noValidate
              spacing={0}
              sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
            >
              {!error ? (
                <>
                  <MainEditForm
                    user={user}
                    thematiques={thematiques}
                    isMobile={isMobile}
                    endpoint="createaccountrequest"
                  />
                  <FooterForm />{' '}
                </>
              ) : (
                <>
                  <Box
                    sx={{ my: 0, textAlign: 'center' }}
                    className="containerMERForm"
                    disableGutters
                  >
                    {ERROR_MESSAGE}
                  </Box>{' '}
                </>
              )}
            </Stack>
          </Paper>
        </Container>
      </>
    );
  } else {
    return <Loader />;
  }
}

export default CreateAccountRequest;
