import React, { useEffect, useContext, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Logout from './components/Logout';
import Signup from './components/Signup';
import CreateAccountRequest from './components/CreateAccountRequest';
import ConfirmEmail from './components/ConfirmEmail';
import Sync from './components/Synchronization/Sync';
import Contact from './components/Contact';
import UserPage from './components/UserPage';
import HomePage from './components/HomePage';
import ForgottenPassword from './components/ForgottenPassword';
import ResetPassword from './components/ResetPassword';
import { SessionContext } from './session';
import MiseEnRelation from './components/MiseEnRelation';
import Gdpr from './components/Gdpr';
import Entities from './components/Entities';
import { SideBar } from './components/SideBar';
import MobileHeader from './components/MobileHeader';
import useAxiosInterceptor from './hooks/useAxiosInterceptor';
import useResizeWindow from './hooks/useResizeWindow';

function RoutesComp() {
  const [session] = useContext(SessionContext);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isMobile } = useResizeWindow();

  const handleOpenNavBar = () => {
    setIsNavOpen(!isNavOpen);
  };

  useAxiosInterceptor();

  return (
    <>
      {session.length > 0 && (
        <>
          <SideBar
            isMobile={isMobile}
            isNavOpen={isNavOpen}
            onClose={() => {
              setIsNavOpen(false);
            }}
          />
          {isMobile && <MobileHeader handleOpen={handleOpenNavBar} />}
        </>
      )}
      <Routes>
        {session.length > 0 && (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/sync" element={<Sync />} />
            <Route path="/relation" element={<MiseEnRelation />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gdpr" element={<Gdpr />} />
            <Route path="/entities" element={<Entities />} />
            <Route path="/login" element={<Login />} />
          </>
        )}
        {!session.length > 0 && (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/forgottenpassword" element={<ForgottenPassword />} />
            <Route
              path="/resetpassword/:temptoken"
              element={<ResetPassword />}
            />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/createaccountrequest"
              element={<CreateAccountRequest />}
            />
            <Route path="/confirmemail/:ppid" element={<ConfirmEmail />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default RoutesComp;
