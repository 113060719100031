import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';
import { SessionContext, setSessionCookie } from '../session';
import { useNavigate } from 'react-router-dom';
import MainLogo from '../resources/main-logo.png';

import {
  Paper,
  Container,
  Link,
  Stack,
  Button,
  Box,
  Divider,
  Typography,
  TextField,
  FilledInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { services } from '../services';
import { ERROR_MESSAGE, EMAIL_WRONG_MESSAGE } from '../constants';
import packageInfo from '../../package.json';

function Login() {
  const [, setSession] = useContext(SessionContext);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    fetchError: false,
    fetchErrorMsg: ''
  });
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const handleChange = (fieldName) => (event) => {
    const currValue = event.target.value;
    if (fieldName === 'email') {
      let isCorrectValue = validator.isEmail(currValue);
      isCorrectValue
        ? setErrors({ ...errors, [fieldName]: false })
        : setErrors({ ...errors, [fieldName]: true });
    }

    setValues({ ...values, [fieldName]: event.target.value });
  };

  const handleShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const body = {
        email: values.email,
        password: values.password
      };
      const data = await services.postData('/api/login', body);
      setSessionCookie(data.token);
      setSession(data.token);
      navigate('/', { replace: true });
      setValues({
        email: '',
        password: '',
        showPassword: false
      });
      return;
    } catch (error) {
      console.log(`Error: ${error}`);
      setErrors({
        ...errors,
        fetchError: true,
        fetchErrorMsg: error.msg ?? ERROR_MESSAGE
      });
    }
  };

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          margin: 'auto'
        }}
        maxWidth="xs"
      >
        <Paper elevation={6}>
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <img src={MainLogo} alt="MainLogo" />
              <h2>Interface journalistes</h2>
            </Container>
          </Container>
          <Stack
            component="form"
            onSubmit={handleSubmit}
            noValidate
            spacing={6}
            sx={{ bgcolor: '#f5f5f6', padding: '40px' }}
          >
            <TextField
              variant="filled"
              type="email"
              label="Email"
              value={values.email}
              onChange={handleChange('email')}
              error={errors.email}
              helperText={errors.email && EMAIL_WRONG_MESSAGE}
            />

            <FormControl variant="filled">
              <InputLabel htmlFor="password-field">Mot de passe</InputLabel>
              <FilledInput
                id="password-field"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                error={errors.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Afficher/Masquer le mot de passe"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Link
                className="coloredTxtForm"
                component={RouterLink}
                to="/forgottenpassword"
                sx={{
                  textAlign: 'right',
                  pt: '5px',
                  pr: '10px',
                  textDecoration: 'none'
                }}
              >
                Mot de passe oublié ?
              </Link>
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                className="btnForm"
                variant="contained"
                size="large"
                type="submit"
                disabled={errors.email || errors.password}
              >
                Connexion
              </Button>
            </Box>
            {errors.fetchError && (
              <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
            )}
            <Divider />
            <Typography paragraph align="center">
              Vous n'avez pas encore de compte?
              <br />
              <Link
                className="coloredTxtForm"
                component={RouterLink}
                to="/createaccountrequest"
              >
                Créer un nouveau compte!
              </Link>
            </Typography>
          </Stack>
        </Paper>
        <Container
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 'auto',
            textAlign: 'center',
            mt: 3,
            fontSize: '0.8rem'
          }}
        >
          Version {packageInfo.version}
          <br />
          Copyright © Matris RP {currentYear}
        </Container>
      </Container>
    </>
  );
}

export default Login;
