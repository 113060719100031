import { Box, CircularProgress } from '@mui/material';

function Loader() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        margin: 'auto'
      }}
    >
      <CircularProgress size="10rem" />
    </Box>
  );
}
export default Loader;
