import React from 'react';
import useGetContacts from '../../hooks/useGetContacts';
import Loader from '../Loader';
import SyncContent from './SyncContent';
function Sync() {
  const { isDataLoaded, hasError, contactTotal } = useGetContacts();

  return isDataLoaded ? (
    <SyncContent contactTotal={contactTotal} hasError={hasError} />
  ) : (
    <Loader />
  );
}

export default Sync;
