import theme from "./theme";
export const customTextField = {
  "& label": {
    color: theme.palette.input.dark,
    fontFamily: "Roboto",
    fontWeight: 500,
    paddingLeft: "25px",
  },
  "& .MuiInputBase-root": {
    paddingLeft: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "16px",
      borderColor: theme.palette.input.light,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.input.light,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.input.light,
    },
  },
};

export const navBarStyle = {
  color: "red",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const navBarListItem = {
  margin: "15px 0 15px 0",
  paddingTop: "0",
  paddingBottom: "0",
};

export const navBarDivider = {
  width: "80%",
  margin: "25px 16px 25px 16px",
};

export const navBarLabel = { marginLeft: "18px", width: "initial" };

export const navBarListItemActive = {
  color: "primary.main",
  borderRight: "3px solid",
  borderRightColor: "primary.main",
  borderRadius: "3px",
  "& .MuiSvgIcon-root": {
    color: "primary.main",
  },
  paddingRight: "13px",
};

export const svgIcon = {
  color: "black",
};

export const SIDEBAR_WIDTH = 300;
export const NAVBAR_HEIGHT = 65;
