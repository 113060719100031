import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  FormLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function LoginPass({ values, errors, handleChange }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <FormControl
        key="credentialsContainer"
        sx={{
          borderRadius: '10px',
          border: 1,
          px: 2,
          pt: 1,
          pb: 3,
          my: 1,
          mt: 3,
          borderColor: 'rgb(0,0,0,0.3)',
          '&:hover': { border: 1, borderColor: 'rgb(0,0,0,1)' }
        }}
      >
        <TextField
          key={'portail_identifiant'}
          variant="standard"
          type="text"
          label="Email identifiant*"
          sx={{ mt: 2 }}
          value={values['portail_identifiant']}
          onChange={handleChange('portail_identifiant')}
        />
        {errors?.identifier_empty && (
          <FormHelperText error={true}>
            L'identifiant est obligatoire!
          </FormHelperText>
        )}
        {(errors?.identifier_wrong && (
          <FormHelperText error={true}>
            L'identifiant doit être un email valide
          </FormHelperText>
        )) ||
          (errors?.identifier_not_unique && (
            <FormHelperText error={true}>
              Cet identifiant existe déjà
            </FormHelperText>
          ))}
        <FormLabel
          style={{
            marginLeft: '0.5em',
            marginTop: '-1.3em',
            paddingLeft: '0.44em',
            zIndex: 2,
            width: '9.3em',
            backgroundColor: '#f5f5f6',
            position: 'absolute',
            fontSize: '1em'
          }}
        >
          Identifiants portail
        </FormLabel>

        <TextField
          id="currentPassword"
          key="portail_password"
          variant="standard"
          label="Mot de passe*"
          sx={{ mt: 2 }}
          type={showPassword ? 'text' : 'password'}
          value={values['portail_password']}
          onChange={handleChange('portail_password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {errors?.password_empty && (
          <FormHelperText error={true}>
            Le mot de passe est obligatoire
          </FormHelperText>
        )}
        {errors?.password_wrong && (
          <FormHelperText error={true}>
            Le mot de passe doit contenir 8 caractères, une lettre en majuscule,
            en minuscule et un chiffre
          </FormHelperText>
        )}
        <FormControl key="profil_is_admin" sx={{ mt: 1 }}>
          <Box display="flex">
            <FormLabel
              id="profil_type_label"
              sx={{ fontSize: '0.7rem', paddingTop: '9px' }}
            >
              Type de compte
            </FormLabel>
            <RadioGroup
              row
              aria-label="profil_type"
              sx={{ pl: 4 }}
              name="profil_is_admin"
              value={values['profil_is_admin'] || false}
              onChange={handleChange('profil_is_admin')}
            >
              <FormControlLabel
                componentsProps={{ typography: { fontSize: '0.7rem' } }}
                value={false}
                control={<Radio size="small" />}
                label="Standard"
              />
              <FormControlLabel
                componentsProps={{ typography: { fontSize: '0.7rem' } }}
                value={true}
                control={<Radio size="small" />}
                label="Administrateur"
              />
            </RadioGroup>
          </Box>
        </FormControl>
      </FormControl>
    </>
  );
}

export default LoginPass;
