const BACK_END_URL = process.env.REACT_APP_BACK_END_URL;
const ERROR_MESSAGE =
  'Il y a eu un problème avec notre serveur, veuillez réessayer plus tard';
const ERROR_MESSAGE_SAVE =
  "Il y a eu un problème lors de l'enregistrement, veuillez réessayer";
const EMPTY_ENTITY_MESSAGE = "Il n'existe aucune Société / Agence";
const EMAIL_WRONG_MESSAGE = 'Veuillez insérer un email valide';
const EMAIL_IS_MANDATORY = "L'email doit être obligatoire";
const EMAIL_EXISTS = 'Cette adresse e-mail est déjà utilisé';
const CLASS_NAMES = {
  MEDIA: 'REDACTION',
  FUNCTION: 'FONCTION_PRESSE'
};
const ENDPOINT = {
  CREATEUSER: 'createuser',
  CREATEACCOUNTREQUEST: 'createaccountrequest'
};
export {
  BACK_END_URL,
  ERROR_MESSAGE,
  ERROR_MESSAGE_SAVE,
  EMPTY_ENTITY_MESSAGE,
  EMAIL_WRONG_MESSAGE,
  CLASS_NAMES,
  EMAIL_IS_MANDATORY,
  ENDPOINT,
  EMAIL_EXISTS
};
