export const formDefinition = [
  { id: 1, name: 'civilite', label: 'Civilité', type: 'text' },
  { id: 2, name: 'prenom', label: 'Prénom', type: 'text' },
  { id: 3, name: 'nom', label: 'Nom*', type: 'text' },
  { id: 4, name: 'pseudo', label: 'Pseudo', type: 'text' },
  { id: 5, name: 'email', label: 'Email*', type: 'text' },
  { id: 6, name: 'portable', label: 'Téléphone mobile', type: 'text' },
  { id: 7, name: 'telephone', label: 'Téléphone fixe', type: 'text' },
  {
    id: 8,
    name: 'statut',
    label: 'Statut',
    type: 'radiobutton',
    content: [
      { id: 'J', label: 'Journaliste intégré' },
      { id: 'P', label: 'Journaliste pigiste' }
    ]
  },
  {
    id: 9,
    name: 'medias',
    label: 'Médias',
    type: 'medias',
    content: [
      {
        id: '1',
        name: 'media',
        label: 'Média',
        type: 'autocomplete',
        completetype: 'REDACTION'
      },
      {
        id: '2',
        name: 'fonction',
        label: 'Fonction',
        type: 'autocomplete',
        completetype: 'FONCTION_PRESSE'
      },
      {
        id: '3',
        name: 'fonctiondetail',
        label: 'Fonction détaillée',
        type: 'text'
      },

      { id: '4', name: 'email', label: 'Email', type: 'text' },
      { id: '5', name: 'telephone', label: 'Téléphone fixe', type: 'text' },
      { id: '6', name: 'portable', label: 'Téléphone mobile', type: 'text' },
      {
        id: '7',
        name: 'adresse1',
        label: 'Adresse première ligne',
        type: 'text'
      },
      {
        id: '8',
        name: 'adresse2',
        label: 'Adresse deuxième ligne',
        type: 'text'
      },
      { id: '9', name: 'cp', label: 'Code Postal', type: 'text' },
      { id: '10', name: 'ville', label: 'Ville', type: 'text' }
    ]
  },
  { id: 10, name: 'linkedin', label: 'LinkedIn', type: 'text' },
  { id: 11, name: 'twitter', label: 'Twitter', type: 'text' },
  { id: 12, name: 'blog', label: 'Blog', type: 'text' },
  {
    id: 13,
    name: 'canal',
    label: 'Canal de prédilection par lequel vous souhaitez être contacté',
    type: 'list',
    content: [
      { id: '', label: 'Aucun / non précisé' },
      { id: 'E', label: 'Email' },
      { id: 'T', label: 'Téléphone' },
      { id: 'R', label: 'Réseaux sociaux' }
    ]
  },
  {
    id: 14,
    name: 'thematiques',
    label: 'Thématiques',
    type: 'thematiques'
  }
];
