import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import Routes from './Routes';
import { SessionContext, getSessionCookie } from './session';
import './App.css';
import './styles/theme.css';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(getSessionCookie());

  useEffect(() => {
    const cookie = getSessionCookie();
    if (session.token !== cookie.token) {
      setSession(cookie);
    }

    setLoading(false);
  }, [session]);

  return (
    <SessionContext.Provider value={[session, setSession]}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>{loading ? <>loading...</> : <Routes />}</BrowserRouter>
      </ThemeProvider>
    </SessionContext.Provider>
  );
}

export default App;
