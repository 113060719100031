import { Button, Box } from '@mui/material';
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Loader from './Loader';

const ModalEntity = ({
  entity,
  modalEntityVisible,
  setModalEntityVisible,
  thematiques
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#f5f5f6',
    border: '1px solid #424242',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };

  return entity.identifier ? (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalEntityVisible}
      onClose={() => {
        setModalEntityVisible(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={modalEntityVisible}>
        <Box sx={style}>
          <Card
            sx={{
              maxWidth: 'auto',
              bgcolor: '#FFFFFF',
              textAlign: 'center',
              mb: 2,
              mx: 2,
              ':hover': { boxShadow: 24, cursor: 'pointer' }
            }}
            key={entity.identifier}
          >
            <CardMedia
              component="img"
              alt={entity.name}
              image={entity.Logo}
              sx={{
                p: 1,
                maxHeight: 100,
                width: 'auto',
                marginX: 'auto'
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {entity.name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textAlign: 'justify' }}
              >
                {entity.pitch}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ my: 1, textAlign: 'justify' }}
              >
                Thématiques :
                {entity.thematiques.map((them) => {
                  var refThematiques = thematiques.filter(
                    (thematique) => thematique.value === them
                  );
                  var themName = refThematiques.map((t) => {
                    return t.label;
                  });
                  var refChildren = thematiques
                    .filter((thematique) => thematique.value === them)
                    .map((t) => {
                      var filteredChildren = t.children.filter((t2) =>
                        entity.thematiques.some((item) => t2.value === item)
                      );
                      return filteredChildren;
                    });
                  return (
                    <div key={them} style={{ marginLeft: '10px' }}>
                      {themName +
                        refChildren.map((c) => {
                          return (
                            ' (' +
                            c.map((m, index) => {
                              return (index > 0 ? ' ' : '') + m.label;
                            }) +
                            ')'
                          );
                        })}
                    </div>
                  );
                })}
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                color="text.secondary"
                component="div"
                sx={{ textAlign: 'left' }}
              >
                {entity.AddressVille
                  ? 'Adresse : ' +
                    (entity.Address1 ? entity.Address1 + ' ' : '') +
                    (entity.Address2 ? entity.Address2 + ' ' : '') +
                    (entity.AddressZip ? entity.AddressZip + ' ' : '') +
                    (entity.AddressVille ? entity.AddressVille : '')
                  : ''}
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                color="text.secondary"
                component="div"
                sx={{ textAlign: 'left' }}
              >
                {entity.website ? 'Site web : ' + entity.website : ''}
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                color="text.secondary"
                component="div"
                sx={{ textAlign: 'left' }}
              >
                {entity.contactDetails
                  ? 'Informations contact : ' + entity.contactDetails
                  : ''}
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                color="text.secondary"
                component="div"
                sx={{ textAlign: 'left' }}
              >
                {entity.customers ? 'Clients : ' + entity.customers : ''}
              </Typography>
              <Button
                sx={{
                  textAlign: 'center',
                  mt: 2
                }}
                variant="outlined"
                size="medium"
                onClick={() => {
                  setModalEntityVisible(false);
                }}
              >
                Fermer
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Fade>
    </Modal>
  ) : (
    <Loader />
  );
};

export default ModalEntity;
