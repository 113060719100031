import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../session';
import FooterForm from './EditFormComponents/FooterForm';
import MainEditForm from './EditFormComponents/MainEditForm';
import MainLogo from '../resources/main-logo.png';
import { Box, Paper, Container, Stack } from '@mui/material';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';
import { services } from '../services';
import useResizeWindow from '../hooks/useResizeWindow';

function Signup() {
  const [session] = useContext(SessionContext);
  const [user, setUser] = useState({});
  const [thematiques, setThematiques] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    services
      .getDefaultUser(session)
      .then((user) => {
        setUser(user);
        services
          .isAdmin(session)
          .then((resp) => {
            if (!resp.isAdmin) {
              navigate('/');
            } else {
              setIsAdmin(resp.isAdmin);
              services
                .getThemes(session)
                .then(setThematiques)
                .catch((error) => console.log(`Error : ${error}`));
            }
          })
          .catch((error) => console.log(`Error : ${error}`));
      })
      .catch((error) => console.log(`Error : ${error}`));
  }, [session, navigate]);

  return isAdmin ? (
    <UserContent user={user} thematiques={thematiques} />
  ) : (
    <Loader />
  );
}

function UserContent({ user, thematiques }) {
  const { isMobile } = useResizeWindow();

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          margin: 'auto'
        }}
        maxWidth="sm"
      >
        <Paper elevation={6} sx={{ mb: isMobile ? 10 : 1 }}>
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
                <h2>Création d'un nouveau profil</h2>
              </Box>
            </Container>
          </Container>
          <Stack
            component="form"
            noValidate
            spacing={0}
            sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
          >
            <MainEditForm
              user={user}
              thematiques={thematiques}
              isMobile={isMobile}
              endpoint="createuser"
            />
            <FooterForm />
          </Stack>
        </Paper>
      </Container>
    </>
  );
}

export default Signup;
