import React, { useState, useEffect } from 'react';
import MainLogo from '../resources/main-logo.png';
import {
  Box,
  Paper,
  Container,
  Stack,
  Button,
  InputAdornment,
  InputLabel,
  FormControl,
  FormHelperText,
  FilledInput,
  IconButton
} from '@mui/material';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import theme from '../styles/theme';
import { services } from '../services';
import { ERROR_MESSAGE } from '../constants';

function ResetPassword() {
  let { temptoken } = useParams();
  return <ResetPasswordContent token={temptoken} />;
}

function ResetPasswordContent({ token }) {
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [valuesPassword, setvaluesPassword] = useState({
    newPassword: '',
    repeatNewPassword: '',
    showNewPassword: false,
    showRepeatNewPassword: false
  });
  const [errors, setErrors] = useState({
    newPassword: false,
    repeatNewPassword: false,
    fetchError: false,
    fetchErrorMsg: '',
    emptyNewPassword: false
  });

  const handleChangePassword = (fieldName) => (event) => {
    var currValue = event.target.value;
    if (fieldName === 'repeatNewPassword') {
      currValue === valuesPassword.newPassword
        ? setErrors({ ...errors, repeatPassword: false })
        : setErrors({ ...errors, repeatPassword: true });
    }
    if (fieldName === 'newPassword') {
      currValue.length > 0
        ? setErrors({ ...errors, emptyNewPassword: false })
        : setErrors({ ...errors, emptyNewPassword: true });
    }

    setvaluesPassword({ ...valuesPassword, [fieldName]: event.target.value });
  };

  const handleShowPassword = (showPasswordField) => {
    setvaluesPassword({
      ...valuesPassword,
      [showPasswordField]: !valuesPassword[showPasswordField]
    });
  };

  const validateChangePassword = async (event) => {
    if (!valuesPassword.newPassword.length > 0) {
      setErrors({ ...errors, emptyNewPassword: true });
    }
    if (!(valuesPassword.newPassword === valuesPassword.repeatNewPassword)) {
      setErrors({ ...errors, repeatPassword: true });
    }
    if (
      !(valuesPassword.newPassword === valuesPassword.repeatNewPassword) ||
      !valuesPassword.newPassword.length > 0
    ) {
      return;
    }
    try {
      const body = {
        newPassword: valuesPassword.newPassword,
        tempToken: token
      };
      await services.postData('/api/changetemppassword', body);
      setShowValidationMessage(true);
      setErrors({
        newPassword: false,
        repeatNewPassword: false,
        fetchError: false,
        fetchErrorMsg: '',
        emptyNewPassword: false
      });
      setvaluesPassword({
        newPassword: '',
        repeatNewPassword: '',
        showNewPassword: false,
        showRepeatNewPassword: false
      });
      return;
    } catch (error) {
      setErrors({
        ...errors,
        fetchError: true,
        fetchErrorMsg: error.msg ?? ERROR_MESSAGE
      });
    }
  };

  useEffect(() => {
    services
      .postData('/api/isTempTokenValid', { tempToken: token })
      .then((resp) => {
        if (resp.auth) setIsTokenValid(true);
      })
      .catch((error) => console.log(`Error: ${error}`));
  }, [token]);

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          margin: 'auto'
        }}
        maxWidth="sm"
      >
        <Paper elevation={6} sx={{ mb: 1 }}>
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
                <h2>Réinitialisation de mot de passe</h2>
              </Box>
            </Container>
          </Container>
          {showValidationMessage ? (
            <Stack
              component="form"
              noValidate
              spacing={0}
              sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
            >
              Votre mot de passe a été changé avec succès, vous pouvez vous
              connecter depuis la page de connexion.
              <Button
                sx={{
                  mt: 2,
                  top: 'auto',
                  right: 'auto',
                  bottom: 0,
                  left: 'auto',
                  backgroundColor: theme.palette.primary.main
                }}
                href="/"
                variant="contained"
                size="large"
              >
                Page de connexion
              </Button>
            </Stack>
          ) : isTokenValid ? (
            <Box>
              <Stack
                component="form"
                noValidate
                spacing={0}
                sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
              >
                <FormControl variant="filled" sx={{ mb: 3 }}>
                  <InputLabel htmlFor="password-field">
                    Nouveau mot de passe
                  </InputLabel>
                  <FilledInput
                    id="newPassword"
                    type={valuesPassword.showNewPassword ? 'text' : 'password'}
                    value={valuesPassword.newPassword}
                    onChange={handleChangePassword('newPassword')}
                    error={errors.newPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleShowPassword('showNewPassword')}
                          edge="end"
                        >
                          {valuesPassword.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.emptyNewPassword && (
                    <FormHelperText error={errors.emptyNewPassword}>
                      Le mot de passe est obligatoire
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl variant="filled">
                  <InputLabel htmlFor="password-field">
                    Confirmation du mot de passe
                  </InputLabel>
                  <FilledInput
                    id="repeatNewPassword"
                    type={
                      valuesPassword.showRepeatNewPassword ? 'text' : 'password'
                    }
                    value={valuesPassword.repeatNewPassword}
                    onChange={handleChangePassword('repeatNewPassword')}
                    error={errors.repeatNewPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleShowPassword('showRepeatNewPassword')
                          }
                          edge="end"
                        >
                          {valuesPassword.showRepeatNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  {errors.repeatPassword && (
                    <FormHelperText error={errors.repeatPassword}>
                      Le mot de passe doit être similaire au précédent
                    </FormHelperText>
                  )}
                </FormControl>
                <FormHelperText sx={{ my: 2 }} error={errors.newPassword}>
                  Les mots de passe doivent contenir 8 caractères, au moins une
                  lettre en majuscule, une lettre en minuscule et un chiffre
                </FormHelperText>
                {errors.fetchError && (
                  <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
                )}
              </Stack>

              <Button
                sx={{
                  margin: 0,
                  top: 'auto',
                  right: 60,
                  bottom: 20,
                  left: 'auto',
                  position: 'fixed',
                  backgroundColor: theme.palette.primary.main
                }}
                variant="contained"
                size="medium"
                onClick={validateChangePassword}
              >
                Valider
              </Button>
            </Box>
          ) : (
            <Stack
              component="form"
              noValidate
              spacing={0}
              sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 4 }}
            >
              <Box
                sx={{ my: 0, textAlign: 'center' }}
                className="containerMERForm"
                disableGutters
              >
                Le jeton d'identification fourni est incorrect!
              </Box>
            </Stack>
          )}
        </Paper>
      </Container>
    </>
  );
}

export default ResetPassword;
