import React from "react";
import { Container } from "@mui/material";

function FooterForm() {
  return (
    <>
      <Container className='containerFooterForm'></Container>
    </>
  );
}

export default FooterForm;
