import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../session';
import ModalPassword from './EditFormComponents/ModalPassword';
import ModalPause from './EditFormComponents/ModalPause';
import MainLogo from '../resources/main-logo.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SyncIcon from '@mui/icons-material/Sync';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from 'react-router-dom';
import theme from '../styles/theme';
import {
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Box,
  Paper,
  Container,
  Stack
} from '@mui/material';
import Loader from './Loader';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import SecurityIcon from '@mui/icons-material/Security';
import KeyIcon from '@mui/icons-material/Key';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { services } from '../services';
import useResizeWindow from '../hooks/useResizeWindow';

function HomePage() {
  const [session] = useContext(SessionContext);
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    Promise.all([services.getUser(session), services.isAdmin(session)]).then(
      ([user, resp]) => {
        setUser(user);
        setIsAdmin(resp.isAdmin);
      }
    );
  }, [session]);

  return user.id > 0 ? (
    <HomePageContent user={user} isAdmin={isAdmin} />
  ) : (
    <Loader />
  );
}

function HomePageContent({ user, isAdmin }) {
  const [modalPasswordVisible, setmodalPasswordVisible] = useState(false);
  const [modalPauseVisible, setmodalPauseVisible] = useState(false);
  const { isMobile } = useResizeWindow;
  const navigate = useNavigate();

  const pauseAccount = () => {
    setmodalPauseVisible(true);
  };

  const changePassword = () => {
    setmodalPasswordVisible(true);
  };

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: isMobile
            ? 'translateX(-50%) translateY(-38%)'
            : 'translateX(-50%) translateY(-45%)',
          margin: 'auto'
        }}
        maxWidth="md"
      >
        <Paper elevation={6} sx={{ mb: isMobile ? 10 : 1 }}>
          <Container
            maxWidth="md"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
              </Box>
            </Container>
          </Container>
          <Container
            sx={{
              bgcolor: '#f5f5f6',
              padding: '30px',
              pt: 1,
              pb: 1,
              alignItems: 'center',
              textAlign: 'center',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
            }}
          >
            <Container className="LogoForm" sx={{ pb: 1 }}>
              <Box textAlign="center">
                <h2>Mon profil</h2>
              </Box>
            </Container>
            <Stack
              component="form"
              noValidate
              spacing={0}
              sx={{
                bgcolor: '#f5f5f6',
                display: 'grid',
                alignItems: 'center',
                textAlign: 'center',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
              }}
            >
              <Card
                sx={{
                  maxWidth: 300,
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  mx: 'auto',
                  width: '300px'
                }}
                onClick={() => {
                  navigate('/user');
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <ContactPageIcon
                      variant="extended"
                      sx={{
                        position: 'absolute',
                        right: '5%',
                        top: '10%',
                        fontSize: 40,
                        color: theme.palette.primary.main
                      }}
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      Mon compte
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 2 }}
                    >
                      Consulter / modifier mes informations
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                sx={{
                  maxWidth: 300,
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  mx: 'auto',
                  width: '300px'
                }}
                onClick={pauseAccount}
              >
                <CardActionArea>
                  <CardContent>
                    <NotificationsPausedIcon
                      variant="extended"
                      sx={{
                        position: 'absolute',
                        right: '5%',
                        top: '10%',
                        fontSize: 40,
                        color: theme.palette.primary.main
                      }}
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      Mise en pause
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 2 }}
                    >
                      Suspendre les notifications pendant une période
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                sx={{
                  maxWidth: 300,
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  mx: 'auto',
                  width: '300px'
                }}
                onClick={changePassword}
              >
                <CardActionArea>
                  <CardContent>
                    <KeyIcon
                      variant="extended"
                      sx={{
                        position: 'absolute',
                        right: '5%',
                        top: '10%',
                        fontSize: 40,
                        color: theme.palette.primary.main
                      }}
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      Mot de passe
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 2 }}
                    >
                      Changer mon mot de passe
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                sx={{
                  maxWidth: 300,
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  mx: 'auto',
                  width: '300px'
                }}
                onClick={() => {
                  navigate('/gdpr');
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <SecurityIcon
                      variant="extended"
                      sx={{
                        position: 'absolute',
                        right: '5%',
                        top: '10%',
                        fontSize: 40,
                        color: theme.palette.primary.main
                      }}
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      Droit de retrait
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 2 }}
                    >
                      Exercer mon droit de retrait de Matris
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                sx={{
                  maxWidth: 300,
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  mx: 'auto',
                  width: '300px'
                }}
                onClick={() => {
                  navigate('/logout');
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <LogoutIcon
                      variant="extended"
                      sx={{
                        position: 'absolute',
                        right: '5%',
                        top: '10%',
                        fontSize: 40,
                        color: theme.palette.primary.main
                      }}
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      Déconnexion
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 2 }}
                    >
                      Me déconnecter de la plateforme Matris
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Stack>
          </Container>
          <Container
            sx={{
              bgcolor: '#f5f5f6',
              padding: '30px',
              pt: 1,
              pb: 1,
              alignItems: 'center',
              textAlign: 'center',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
            }}
          >
            <Container className="LogoForm" sx={{ pb: 1 }}>
              <Box textAlign="center">
                <h2>Mon réseau</h2>
              </Box>
            </Container>
            <Stack
              component="form"
              noValidate
              spacing={0}
              sx={{
                bgcolor: '#f5f5f6',
                display: 'grid',
                alignItems: 'center',
                textAlign: 'center',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
              }}
            >
              <Card
                sx={{
                  maxWidth: 300,
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  mx: 'auto',
                  width: '300px'
                }}
                onClick={() => {
                  navigate('/relation');
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <ContactMailIcon
                      variant="extended"
                      sx={{
                        position: 'absolute',
                        right: '5%',
                        top: '10%',
                        fontSize: 40,
                        color: theme.palette.primary.main
                      }}
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      Mise en relation
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 2 }}
                    >
                      Trouver de l'information sur un sujet précis
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              {isAdmin && (
                <Card
                  sx={{
                    maxWidth: 300,
                    bgcolor: '#FFFFFF',
                    mb: 2,
                    mx: 'auto',
                    width: '300px'
                  }}
                  onClick={() => {
                    navigate('/entities');
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <BusinessIcon
                        variant="extended"
                        sx={{
                          position: 'absolute',
                          right: '5%',
                          top: '10%',
                          fontSize: 40,
                          color: theme.palette.primary.main
                        }}
                      />
                      <Typography gutterBottom variant="h5" component="div">
                        Sociétés / Agences
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 2 }}
                      >
                        Consulter les entités utilisant la base Matris
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}
            </Stack>
          </Container>
          {isAdmin && (
            <Container
              sx={{
                bgcolor: '#f5f5f6',
                padding: '30px',
                pt: 1,
                pb: 1,
                alignItems: 'center',
                textAlign: 'center',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
              }}
            >
              <Container className="LogoForm" sx={{ pb: 1 }}>
                <Box textAlign="center">
                  <h2>Espace administrateur</h2>
                </Box>
              </Container>

              <Stack
                component="form"
                noValidate
                spacing={0}
                sx={{
                  bgcolor: '#f5f5f6',
                  display: 'grid',
                  alignItems: 'center',
                  textAlign: 'center',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
                }}
              >
                <Card
                  sx={{
                    maxWidth: 300,
                    bgcolor: '#FFFFFF',
                    mb: 2,
                    mx: 'auto',
                    width: '300px'
                  }}
                  onClick={() => {
                    navigate('/signup');
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <PersonAddIcon
                        variant="extended"
                        sx={{
                          position: 'absolute',
                          right: '5%',
                          top: '10%',
                          fontSize: 40,
                          color: theme.palette.primary.main
                        }}
                      />
                      <Typography gutterBottom variant="h5" component="div">
                        Créer un profil
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 2 }}
                      >
                        Ajouter un profil journaliste
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  sx={{
                    maxWidth: 300,
                    bgcolor: '#FFFFFF',
                    mb: 2,
                    mx: 'auto',
                    width: '300px'
                  }}
                  onClick={() => {
                    navigate('/sync');
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <SyncIcon
                        variant="extended"
                        sx={{
                          position: 'absolute',
                          right: '5%',
                          top: '10%',
                          fontSize: 40,
                          color: theme.palette.primary.main
                        }}
                      />
                      <Typography gutterBottom variant="h5" component="div">
                        Synchroniser
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 2 }}
                      >
                        Synchroniser les comptes journalistes depuis la base
                        Matris
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Stack>
            </Container>
          )}
        </Paper>
      </Container>
      <ModalPassword
        modalPasswordVisible={modalPasswordVisible}
        setmodalPasswordVisible={setmodalPasswordVisible}
      />
      <ModalPause
        user={user}
        modalPauseVisible={modalPauseVisible}
        setmodalPauseVisible={setmodalPauseVisible}
      />
    </>
  );
}

export default HomePage;
