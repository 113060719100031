import React, { useState, useEffect } from 'react';
import MainLogo from '../resources/main-logo.png';

import {
  Box,
  Paper,
  Container,
  Stack,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Link
} from '@mui/material';
import useResizeWindow from '../hooks/useResizeWindow';

function Contact() {
  return <ContactContent />;
}

function ContactContent() {
  const { isMobile } = useResizeWindow();

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          margin: 'auto'
        }}
        maxWidth="sm"
      >
        <Paper elevation={6} sx={{ mb: isMobile ? 10 : 1 }}>
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
                <h2>Informations de contact</h2>
              </Box>
            </Container>
            <Stack
              component="form"
              noValidate
              spacing={0}
              sx={{ bgcolor: '#f5f5f6', m: 2, width: '100%' }}
            >
              <Card
                sx={{ bgcolor: '#FFFFFF', pb: 2, mx: 'auto', width: '100%' }}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Informations de contact
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 2 }}
                    >
                      Si vous avez une question ou souhaitez entrer en contact
                      avec les équipes Matris RP vous pouvez envoyer un email à{' '}
                      <Link href="mailto:data@matris-rp.com">
                        data@matris-rp.com
                      </Link>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Stack>
          </Container>
        </Paper>
      </Container>
    </>
  );
}

export default Contact;
