import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import MainLogo from "../resources/main-logo.png";
const MobileHeader = (props) => {
  const container = {
    position: "fixed",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    zIndex: "9999",
    backgroundColor: "#e1e2e1",
    top: "0",
    py: "10px",
    boxShadow: "rgba(149, 157, 165, 0.5) 0px 8px 24px",
  };
  const navigate = useNavigate();
  const handleNavigate = (navigation) => {
    navigate(navigation);
  };

  return (
    <Box sx={container}>
      <Button onClick={() => props.handleOpen()}>
        <Menu fontSize="large" />
      </Button>
      <Box component="img" onClick={() => handleNavigate("/")} src={MainLogo} sx={{ width: "auto", height: "40px" }} />
    </Box>
  );
};

export default MobileHeader;
