import React, { useState, useEffect } from 'react';
import MainLogo from '../resources/main-logo.png';
import { Box, Paper, Container, Stack } from '@mui/material';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useParams } from 'react-router-dom';
import { services } from '../services';
import { ERROR_MESSAGE } from '../constants';
import Loader from './Loader';

function ConfirmEmailContent() {
  const [hasError, setHasError] = useState(false);
  const [linkAvailable, setLinkAvailable] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  let { ppid } = useParams();
  useEffect(() => {
    services
      .postData('/api/confirmemail', { ppid: ppid })
      .then((resp) => {
        setLinkAvailable(!resp.linkNotAvailable);
      })
      .catch((error) => {
        setHasError(true);
      })
      .finally(() => {
        setIsDataLoaded(true);
      });
  }, [ppid]);

  if (isDataLoaded) {
    return (
      <>
        <Container
          sx={{
            position: 'absolute',
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 'auto'
          }}
          maxWidth="sm"
        >
          <Paper elevation={6} sx={{ mb: 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '20px'
              }}
            >
              <Container className="LogoForm">
                <Box textAlign="center">
                  <img src={MainLogo} alt="Main logo" />
                  <h2>Confirmation de l'e-mail</h2>
                </Box>
              </Container>
            </Container>
            {
              <Stack
                component="form"
                noValidate
                spacing={0}
                sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
              >
                {!hasError ? (
                  linkAvailable ? (
                    <p>
                      Bienvenue parmi nous ! Bientôt, vous recevrez un e-mail
                      avec un lien pour définir votre mot de passe et accéder à
                      votre compte.
                    </p>
                  ) : (
                    <p>
                      Le lien n'est plus valide. Veuillez soumettre une nouvelle
                      demande de création de compte. Merci.
                    </p>
                  )
                ) : (
                  <p>{ERROR_MESSAGE}</p>
                )}
              </Stack>
            }
          </Paper>
        </Container>
      </>
    );
  }
  return <Loader />;
}

export default ConfirmEmailContent;
