import { useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../session";

function Logout() {
  const navigate = useNavigate();
  const [, setSession] = useContext(SessionContext);
  useEffect(() => {
    Cookies.remove("matrisToken");
    setSession("");
    navigate("/");
  });
  return;
}

export default Logout;
