import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#684EF2",
      light: "#684EF2",
      dark: "#684EF2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#684EF2",
      light: "#684EF2",
      dark: "#684EF2",
      contrastText: "#fff",
    },
    input: {
      light: "#D9D9D9",
      dark: "#303030",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#e1e2e1",
        },
      },
    },
    CheckBox: {
      fontSize: 20,
    },
  },
  typography: {
    fontSize: 12,
  },
});

theme = responsiveFontSizes(theme);

export default theme;
