import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { services } from '../services';
import { SessionContext } from '../session';

function useGetContacts() {
  const [session] = useContext(SessionContext);
  const [contactTotal, setContactTotal] = useState({
    totalSynContacts: 0,
    totalNewSynContacts: 0
  });
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const isInitialized = useRef(false);
  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      services
        .isAdmin(session)
        .then((resp) => {
          if (!resp.isAdmin) {
            navigate('/');
          } else {
            services.getContacts(session).then((contactTotal) => {
              setContactTotal({
                totalSynContacts: contactTotal.totalSynContacts,
                totalNewSynContacts: contactTotal.totalNewSynContacts
              });
            });
          }
        })
        .catch(() => setHasError(true))
        .finally(() => {
          setIsDataLoaded(true);
        });
    }
  }, []);

  return { isDataLoaded, hasError, contactTotal };
}
export default useGetContacts;
