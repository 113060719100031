import React, { useContext, useState, useEffect, useRef } from 'react';
import { SessionContext } from '../session';
import MainLogo from '../resources/main-logo.png';
import theme from '../styles/theme';
import {
  Box,
  Paper,
  Container,
  Stack,
  FormControl,
  TextField,
  Button,
  FormLabel,
  FormHelperText,
  Grow,
  Autocomplete,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import Loader from './Loader';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import { services } from '../services';
import { isEmailAddressValid } from '../emailValidator';
import { EMAIL_WRONG_MESSAGE, CLASS_NAMES } from '../constants';
import useResizeWindow from '../hooks/useResizeWindow';

function MiseEnRelation() {
  const [session] = useContext(SessionContext);
  const [user, setUser] = useState({});
  const [thematiques, setThematiques] = useState([]);

  useEffect(() => {
    services.getUser(session).then((user) => {
      setUser(user);
    });
    services.getThemes(session).then((themes) => {
      setThematiques(themes);
    });
  }, [session]);

  return user.id > 0 ? (
    <MiseEnrelationContent
      user={user}
      thematiques={thematiques}
      session={session}
    />
  ) : (
    <Loader />
  );
}

function MiseEnrelationContent({ user, thematiques, session }) {
  const [values, setValues] = useState({
    objet: '',
    contenu: '',
    media: null,
    email: '',
    telephone: '',
    dateexpiration: new Date(),
    thematiques: user.thematiques
  });
  const [showMessage, setShowMessage] = useState(false);
  const [state, setState] = useState({
    checked: values.thematiques,
    expanded: []
  });
  const { isMobile } = useResizeWindow();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [errors, setErrors] = useState({
    empty_objet: false,
    empty_contenu: false,
    empty_thematiques: false,
    wrong_email: false,
    checkbox_checked: false
  });
  const [medias, setMedias] = useState([]);
  const previousController = useRef();
  useEffect(() => {
    const tempValues = values;
    tempValues.thematiques = state.checked;
    setValues(tempValues);
  }, [state, values]);

  const handleChange = (fieldName) => (event) => {
    var value;
    if (fieldName == 'dateexpiration') value = event?.toISOString();
    else value = event.target.value;
    setValues({ ...values, [fieldName]: value });
    switch (fieldName) {
      case 'objet':
        setErrors((errors) => ({
          ...errors,
          empty_objet: value === ''
        }));
        break;
      case 'contenu':
        setErrors((errors) => ({
          ...errors,
          empty_contenu: value === ''
        }));
        break;
      case 'email':
        setErrors((errors) => ({
          ...errors,
          wrong_email: value && !isEmailAddressValid(value)
        }));
        break;
    }
  };

  const handleMediaChange = (value) => {
    setValues({ ...values, ['media']: value });
  };

  const onMediaChange = (event, value) => {
    const searchTermMedia = {
      preController: previousController,
      keyword: value,
      session: session,
      className: CLASS_NAMES.MEDIA,
      callback: setMedias
    };
    if (value && value.length > 1) {
      services.getSamples(searchTermMedia);
    } else {
      setMedias([]);
    }
  };

  const sendRequest = async () => {
    const objectEmpty = values.objet.length === 0;
    const contentEmpty = values.contenu.length === 0;
    const themeEmpty = values.thematiques.length === 0;
    const emailWrong = values.email && !isEmailAddressValid(values.email);

    setErrors({
      ...errors,
      empty_objet: objectEmpty,
      empty_contenu: contentEmpty,
      empty_thematiques: themeEmpty,
      wrong_email: emailWrong,
      checkbox_checked: checkboxChecked
    });
    if (
      objectEmpty ||
      contentEmpty ||
      themeEmpty ||
      emailWrong ||
      !checkboxChecked
    ) {
      return false;
    } else {
      try {
        await services.postData('/api/createrequest', values, session);
        setShowMessage(true);
      } catch (error) {
        console.log(`Error: ${error}`);
        return error;
      }
    }
  };
  const handlecheckBoxChange = (event) => {
    setCheckboxChecked(event.target.checked);
    setErrors({
      ...errors,
      checkbox_checked: event.target.checked
    });
  };

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: isMobile ? '80px' : '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          margin: 'auto'
        }}
        maxWidth="sm"
      >
        <Paper elevation={6} sx={{ mb: isMobile ? 10 : 1 }}>
          <Container
            maxWidth="sm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
                <h2>Mise en relation</h2>
              </Box>
            </Container>
          </Container>
          <Stack
            component="form"
            noValidate
            spacing={0}
            sx={{ bgcolor: '#f5f5f6', padding: '30px', pt: 2 }}
          >
            <Box sx={{ my: 0 }} className="containerMERForm">
              Vous avez besoin d'informations sur un sujet précis? Formulez
              votre demande et renseignez la thématique concernée ci-dessous,
              les utilisateurs de Matris seront notifiés et reviendront vers
              vous.
            </Box>
            <FormControl
              key="demande"
              sx={{
                borderRadius: '10px',
                border: 1,
                p: 1,
                my: 2,
                borderColor: 'rgb(0,0,0,0.3)',
                '&:hover': { border: 1, borderColor: 'rgb(0,0,0,1)' }
              }}
            >
              <FormLabel
                style={{
                  marginLeft: '0.5em',
                  marginTop: '-1.3em',
                  paddingLeft: '0.44em',
                  zIndex: 2,
                  width: '6em',
                  backgroundColor: '#f5f5f6',
                  position: 'absolute',
                  fontSize: '1em'
                }}
              >
                Demande
              </FormLabel>
              <TextField
                key="objet"
                variant="standard"
                type="text"
                label="Objet de la demande"
                value={values.objet}
                onChange={handleChange('objet')}
                sx={{ px: 2, '& label': { px: 2 } }}
              />
              {errors.empty_objet && (
                <FormHelperText error={errors.empty_objet}>
                  L'objet de la demande est obligatoire
                </FormHelperText>
              )}
              <TextField
                key="contenu"
                variant="standard"
                type="text"
                label="Contenu de la demande"
                multiline={true}
                rows="5"
                value={values.contenu}
                onChange={handleChange('contenu')}
                sx={{ px: 2, '& label': { px: 2 }, mb: 2 }}
              />
              {errors.empty_contenu && (
                <FormHelperText error={errors.empty_contenu}>
                  Le contenu de la demande est obligatoire
                </FormHelperText>
              )}
              <Autocomplete
                key="media"
                options={medias.map((option) => ({
                  id: option.id,
                  label: option.title
                }))}
                onInputChange={onMediaChange}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name || value === '' || value == null
                }
                onChange={(e, value) => handleMediaChange(value?.id)}
                className="inputMatris"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Média"
                    variant="outlined"
                    sx={{ width: '100%', my: 1 }}
                  />
                )}
              />
              <TextField
                key="email"
                variant="outlined"
                type="text"
                label="Email"
                value={values.email}
                onChange={handleChange('email')}
                sx={{ width: '100%', my: 1 }}
              />
              {errors.wrong_email && (
                <FormHelperText error={true}>
                  {EMAIL_WRONG_MESSAGE}
                </FormHelperText>
              )}
              <TextField
                key="telephone"
                className="inputMatris"
                variant="outlined"
                type="text"
                label="Téléphone"
                value={values.telephone}
                onChange={handleChange('telephone')}
                sx={{ width: '100%', my: 1 }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date d'expiration*"
                  value={dayjs(values.dateexpiration)}
                  onChange={handleChange('dateexpiration')}
                  sx={{ width: '100%', my: 1 }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl
              key="thematiques"
              sx={{
                borderRadius: '10px',
                border: 1,
                p: 1,
                mt: 2,
                mb: 3,
                borderColor: 'rgb(0,0,0,0.3)',
                '&:hover': { border: 1, borderColor: 'rgb(0,0,0,1)' }
              }}
            >
              <FormLabel
                style={{
                  marginLeft: '0.5em',
                  marginTop: '-1.3em',
                  paddingLeft: '0.44em',
                  zIndex: 2,
                  width: '6em',
                  backgroundColor: '#f5f5f6',
                  position: 'absolute',
                  fontSize: '1em'
                }}
              >
                Thématiques
              </FormLabel>
              <Box>
                <CheckboxTree
                  sx={{ display: 'flex', flexDirection: 'column' }}
                  height="50%"
                  showNodeIcon={false}
                  nodes={thematiques}
                  checked={state.checked}
                  expanded={state.expanded}
                  onCheck={(checked) =>
                    setState({ ...state, checked: checked })
                  }
                  onExpand={(expanded) =>
                    setState({ ...state, expanded: expanded })
                  }
                />
                {errors.empty_thematiques && (
                  <FormHelperText error={errors.empty_thematiques}>
                    Il faut cocher au moins une thématique
                  </FormHelperText>
                )}
              </Box>
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                onChange={handlecheckBoxChange}
                label="Je suis conscient que cette demande sera adressée à tous les communicants utilisateurs de Matris."
              />
              {!errors.checkbox_checked && (
                <FormHelperText error={true}>
                  Veuillez cocher la case pour continuer
                </FormHelperText>
              )}
            </FormGroup>
            {!showMessage && (
              <Button
                sx={{
                  margin: 1,
                  top: 'auto',
                  right: 'auto',
                  bottom: 0,
                  left: 'auto',
                  backgroundColor: theme.palette.primary.main
                }}
                variant="contained"
                size="large"
                onClick={sendRequest}
              >
                Envoyer
              </Button>
            )}
            {showMessage && (
              <Grow
                in={showMessage}
                style={{ transformOrigin: '0 0 0' }}
                {...(showMessage ? { timeout: 1000 } : {})}
              >
                <Container sx={{ mt: 2, ml: 0, pl: 0, textAlign: 'center' }}>
                  Votre demande a bien été enregistrée!
                </Container>
              </Grow>
            )}
          </Stack>
        </Paper>
      </Container>
    </>
  );
}

export default MiseEnRelation;
