import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../session';
import MainLogo from '../resources/main-logo.png';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Box,
  Paper,
  Container,
  Stack,
  CardContent,
  CardMedia,
  Typography,
  Autocomplete,
  TextField
} from '@mui/material';
import { styled } from '@mui/system';
import ModalEntity from './ModalEntity';
import { services } from '../services';
import { EMPTY_ENTITY_MESSAGE, ERROR_MESSAGE } from '../constants';
import Loader from './Loader';
import useResizeWindow from '../hooks/useResizeWindow';

function Entities() {
  const [session] = useContext(SessionContext);
  const [entities, setEntities] = useState([]);
  const [thematiques, setThematiques] = useState([]);
  const [thematiquesFlat, setThematiquesFlat] = useState([]);
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchEntities = async () => {
      const resp = await services.isAdmin(session);
      if (!resp.isAdmin) {
        navigate('/');
      } else {
        return await services.getEntities(session);
      }
    };
    Promise.all([
      fetchEntities(),
      services.getThemes(session),
      services.getFlatThemes(session)
    ])
      .then(([entities, themes, flatThemes]) => {
        setEntities(entities);
        setThematiques(themes);
        setThematiquesFlat(flatThemes);
        setDataLoaded(true);
      })
      .catch((error) => {
        setDataLoaded(true);
        setError(true);
      });
  }, [session, navigate]);

  if (dataLoaded) {
    return (
      <EntitiesContent
        entities={entities}
        thematiques={thematiques}
        thematiquesFlat={thematiquesFlat}
        error={error}
      />
    );
  } else {
    return <Loader />;
  }
}

function EmptyEntity({ error }) {
  const messageToDisplay = error ? ERROR_MESSAGE : EMPTY_ENTITY_MESSAGE;
  return <Box sx={{ pb: '35px', textAlign: 'center' }}>{messageToDisplay}</Box>;
}

function EntitiesContent({ entities, thematiques, thematiquesFlat, error }) {
  const [selectedEntity, setSelectedEntity] = useState(entities[0]);
  const [modalEntityVisible, setModalEntityVisible] = useState(false);
  const [filteredEntities, setFilteredEntities] = useState(entities);
  const { isMobile } = useResizeWindow();
  const [acValue, setAcValue] = useState([]);
  const [inputAcValue, setInputAcValue] = useState('');

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: '#f5f5f6'
  }));

  const GroupItems = styled('ul')({
    padding: 1,
    backgroundColor: '#FFFFFF'
  });

  function filterEntities(f) {
    setAcValue(f);
    if (f.length > 0) {
      var listCodes = f.map((f) => f.code);
      setFilteredEntities(
        entities.filter((e) =>
          e.thematiques.some((item) => listCodes.includes(item))
        )
      );
    } else {
      setFilteredEntities(entities);
    }
  }

  function pickEntity(entity) {
    setSelectedEntity(entity);
    setModalEntityVisible(true);
  }

  return (
    <>
      <Container
        sx={{
          position: 'absolute',
          top: isMobile ? '50%' : '5%',
          left: '50%',
          transform: isMobile
            ? 'translateX(-50%) translateY(-38%)'
            : 'translateX(-50%)',
          margin: 'auto'
        }}
        maxWidth="sm"
      >
        <Paper elevation={6} sx={{ mb: isMobile ? 10 : 1 }}>
          <Container
            maxWidth="md"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px'
            }}
          >
            <Container className="LogoForm">
              <Box textAlign="center">
                <img src={MainLogo} alt="Main logo" />
                <h2>Sociétés / Agences utilisant la base Matris</h2>
              </Box>
            </Container>
          </Container>
          {entities?.length > 0 && (
            <>
              <Container
                sx={{
                  bgcolor: '#f5f5f6',
                  pt: 1,
                  pb: 1,
                  alignItems: 'center',
                  textAlign: 'center',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
                }}
              >
                <Autocomplete
                  multiple
                  sx={{ mb: 2 }}
                  id="filter"
                  value={acValue}
                  onChange={(event, newValue) => {
                    filterEntities(newValue);
                  }}
                  inputValue={inputAcValue}
                  onInputChange={(event, newInputValue) => {
                    setInputAcValue(newInputValue);
                  }}
                  options={thematiquesFlat.sort(
                    (a, b) => -b.parent.localeCompare(a.parent)
                  )}
                  groupBy={(thematique) => thematique.parent}
                  getOptionLabel={(thematique) => thematique.label}
                  renderGroup={(params) => (
                    <li key={params.group}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choisissez une thématique pour filtrer les résultats"
                      variant="standard"
                      width="500px"
                    />
                  )}
                />
              </Container>
              <Stack
                component="form"
                noValidate
                spacing={0}
                sx={{
                  bgcolor: '#f5f5f6',
                  display: 'grid',
                  alignItems: 'center',
                  textAlign: 'center',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(245px, 1fr))'
                }}
              >
                {filteredEntities.map((entity) => {
                  return (
                    <Card
                      sx={{
                        maxWidth: 'auto',
                        maxHeight: 300,
                        bgcolor: '#FFFFFF',
                        textAlign: 'justify',
                        mb: 2,
                        mx: 2,
                        ':hover': { boxShadow: 24, cursor: 'pointer' }
                      }}
                      key={entity.identifier}
                      onClick={() => pickEntity(entity)}
                    >
                      <CardMedia
                        component="img"
                        alt={entity.name}
                        image={entity.Logo}
                        sx={{
                          p: 1,
                          maxHeight: 100,
                          width: 'auto',
                          marginX: 'auto'
                        }}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {entity.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          sx={{ mb: 1 }}
                        >
                          Thématiques :{' '}
                          {entity.thematiques.map((them) => {
                            var refThematiques = thematiques.filter(
                              (thematique) => thematique.value === them
                            );
                            var themName = refThematiques.map((t) => {
                              return t.label;
                            });
                            var refChildren = thematiques
                              .filter((thematique) => thematique.value === them)
                              .map((t) => {
                                var filteredChildren = t.children.filter((t2) =>
                                  entity.thematiques.some(
                                    (item) => t2.value === item
                                  )
                                );
                                return filteredChildren;
                              });
                            return (
                              <div key={them} style={{ marginLeft: '10px' }}>
                                {themName +
                                  refChildren.map((c) => {
                                    return (
                                      ' (' +
                                      c.map((m, index) => {
                                        return (index > 0 ? ' ' : '') + m.label;
                                      }) +
                                      ')'
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {entity.pitch}
                        </Typography>
                      </CardContent>
                    </Card>
                  );
                })}
              </Stack>
            </>
          )}
          {!entities?.length && <EmptyEntity error={error} />}
        </Paper>
      </Container>
      {selectedEntity && (
        <ModalEntity
          entity={selectedEntity}
          modalEntityVisible={modalEntityVisible}
          setModalEntityVisible={setModalEntityVisible}
          thematiques={thematiques}
        />
      )}
    </>
  );
}

export default Entities;
