import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AxiosInterceptorsSetup = (navigate, location) => {
  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };
    const errInterceptor = (error) => {
      if (error.response) {
        console.log(`Error data message: ${error.response.data.msg}`);
        console.log(`Error status: ${error.response.status}`);
        if (error.response.status === 401) {
          error.msg = 'Identifiant invalide';
          if (location.pathname !== '/') navigate('/logout');
        } else if (error.response.status === 404) {
          if (location.pathname === '/') error.msg = 'Utilisateur non trouvé';
          else error.msg = 'Page non trouvée!';
        }
      }
      return Promise.reject(error);
    };

    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => axios.interceptors.response.eject(interceptor);
  }, [location.pathname, navigate]);
};

function useAxiosInterceptor() {
  const navigate = useNavigate();
  const location = useLocation();
  AxiosInterceptorsSetup(navigate, location);
}

export default useAxiosInterceptor;
