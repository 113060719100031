import React from "react";
import Cookies from "js-cookie";

export const setSessionCookie = (session) => {
  Cookies.remove("matrisToken");
  Cookies.set("matrisToken", JSON.stringify(session), { expires: 1 });
};

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get("matrisToken");
  if (sessionCookie === "undefined" || sessionCookie === undefined) {
    return {};
  } else {
    return JSON.parse(sessionCookie);
  }
};

export const SessionContext = React.createContext(getSessionCookie());
