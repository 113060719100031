import axios from 'axios';
import { BACK_END_URL } from './constants';
const contentType = 'application/json';

function getUser(session) {
  return fetchData('/api/user', session);
}

function getDefaultUser(session) {
  return fetchData('/api/defaultuser', session);
}

function isAdmin(session) {
  return fetchData('/api/isadmin', session);
}

function getThemes(session) {
  return fetchData('/api/thematiques', session);
}

function getEntities(session) {
  return fetchData('/api/entities', session);
}
function getFlatThemes(session) {
  return fetchData('/api/thematiquesflat', session);
}

function getContacts(session) {
  return fetchData('/api/sync', session);
}

async function getSamples(searchTerm) {
  if (searchTerm.preController.current) {
    searchTerm.preController.current.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  searchTerm.preController.current = controller;
  const url = `/api/getsample?class=${searchTerm.className}&pattern=${searchTerm.keyword}`;
  fetchDataWithSignal(url, signal, searchTerm.session)
    .then(function (myJson) {
      const updatedOptions = myJson.map((p) => {
        return { title: p.label, id: p.id };
      });
      searchTerm.callback(updatedOptions);
    })
    .catch((error) => console.log(`Error : ${error}`));
}

async function postData(url, body, session) {
  const headers = { 'Content-Type': contentType };
  if (session) {
    headers['x-access-token'] = session;
  }
  const { data } = await axios.post(BACK_END_URL + url, body, {
    headers
  });
  return data;
}

async function fetchDataWithSignal(url, signal, session) {
  const headers = {
    'Content-Type': contentType,
    Accept: contentType,
    'x-access-token': session
  };
  const { data } = await axios.get(BACK_END_URL + url, {
    signal,
    headers
  });
  return data;
}

async function fetchData(url, session) {
  const headers = { 'Content-Type': contentType };
  if (session) {
    headers['x-access-token'] = session;
  }
  const { data } = await axios.get(BACK_END_URL + url, {
    headers
  });
  return data;
}

export const services = {
  getUser,
  isAdmin,
  getDefaultUser,
  getThemes,
  getEntities,
  getFlatThemes,
  getContacts,
  fetchDataWithSignal,
  postData,
  getSamples
};
